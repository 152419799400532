// eslint-disable-next-line filenames/match-regex
import type React from 'react'
import {Link, Text, Box} from '@primer/react'
import TipsContentList from './TipsContentList'
import {provideFeedback} from '../../hooks/use-search-bar-events'
import type {SearchKind, SearchKindCount} from '../../hooks/use-search-result-counts'
export interface NoResultDataItem {
  content: React.ReactNode
  name: string
  show: Array<SearchKindCount['name']>
}

export function generateTips({resultKind}: {resultKind: SearchKind}): NoResultDataItem[] {
  return generate().filter(t => t.show.includes(resultKind.name))
}

function generate(): NoResultDataItem[] {
  return [
    {
      name: 'Search across repositories',
      show: [
        'code',
        'codelegacy',
        'issues',
        'pullrequests',
        'commits',
        'discussions',
        'registrypackages',
        'marketplace',
        'topics',
        'wikis',
        'users',
      ],
      content: (
        <>
          <TipsContentList
            data={[
              {
                name: 'Within a repository:',
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>repo:</Text>
                    github/linguist
                  </>
                ),
              },
              {
                name: 'Across several:',
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>repo:</Text>
                    github/linguist <Text sx={{color: 'done.fg'}}>OR</Text> <Text sx={{color: 'accent.fg'}}>repo:</Text>
                    github/fetch
                  </>
                ),
              },
            ]}
          />
          <Box
            sx={{
              mt: 2,
              fontSize: 0,
              color: 'fg.muted',
            }}
          >
            Note that we don&apos;t currently support regular expressions in the repo or org qualifiers. For more
            information on search syntax, see our{' '}
            <Link
              inline
              href="https://docs.github.com/search-github/github-code-search/understanding-github-code-search-syntax"
            >
              syntax guide
            </Link>
            .
          </Box>
        </>
      ),
    },
    {
      name: 'Search across an organization',
      show: [
        'code',
        'codelegacy',
        'repositories',
        'issues',
        'pullrequests',
        'commits',
        'discussions',
        'registrypackages',
        'marketplace',
        'topics',
        'wikis',
        'users',
      ],
      content: (
        <>
          <TipsContentList
            data={[
              {
                name: 'Within an organization:',
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>org:</Text>
                    rails
                  </>
                ),
              },
              {
                name: `User's code:`,
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>user:</Text>
                    mona
                  </>
                ),
              },
            ]}
          />
          <Box
            sx={{
              mt: 2,
              fontSize: 0,
              color: 'fg.muted',
            }}
          >
            Note that we don&apos;t currently support regular expressions in the repo or org qualifiers. For more
            information on search syntax, see our{' '}
            <Link
              inline
              href="https://docs.github.com/search-github/github-code-search/understanding-github-code-search-syntax"
            >
              syntax guide
            </Link>
            .
          </Box>
        </>
      ),
    },
    {
      name: 'Find a particular file extension',
      show: ['code', 'codelegacy'],
      content: (
        <>
          <TipsContentList
            data={[
              {
                name: 'With .txt extensions:',
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>path:</Text>
                    *.txt
                  </>
                ),
              },
              {
                name: `JavaScript and TypeScript files:`,
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>path:</Text>
                    *.js <Text sx={{color: 'done.fg'}}>OR</Text> <Text sx={{color: 'accent.fg'}}>path:</Text>*.ts
                  </>
                ),
              },
            ]}
          />
          <Box
            sx={{
              mt: 2,
              fontSize: 0,
              color: 'fg.muted',
            }}
          >
            The path qualifier can search the entire file path, not just the extension, and supports regular
            expressions. For more information, see our{' '}
            <Link
              inline
              href="https://docs.github.com/search-github/github-code-search/understanding-github-code-search-syntax"
            >
              syntax guide
            </Link>
            .
          </Box>
        </>
      ),
    },
    {
      name: "Why wasn't my code found?",
      show: ['code'],
      content: (
        <>
          <Text
            as="p"
            sx={{
              fontSize: 0,
            }}
          >
            When you search within a repository for the first time, please note that the repository undergoes indexing.{' '}
            <Text sx={{fontWeight: 'bold'}}>This process may take a few minutes.</Text>{' '}
          </Text>
          <Text as="p" sx={{display: 'block'}}>
            The index currently includes more than 70 million popular public repositories, plus all private repositories
            that users search for.
          </Text>
          <p>Beyond that, we also don&apos;t include all files in the search index:</p>
          <Box as="p" sx={{pl: 3}}>
            <ul>
              <li>Vendored and generated code is excluded</li>
              <li>Empty files and files over 350 kiB are excluded</li>
              <li>Only UTF-8 encoded files are indexed</li>
              <li>Very large repositories may not be indexed</li>
            </ul>
          </Box>

          <Text
            as="p"
            sx={{
              fontSize: 0,
              color: 'fg.muted',
            }}
          >
            We intend to continue to increase the amount of code available in the index as much as possible. If we are
            missing files that are useful to you, feel free to{' '}
            <Link
              inline
              href="#"
              onClick={(e: React.MouseEvent) => {
                provideFeedback()
                e.preventDefault()

                // NOTE: This is necessary to prevent the dialog from instantly closing
                e.stopPropagation()
              }}
            >
              provide feedback here
            </Link>
            .
          </Text>
        </>
      ),
    },
    {
      name: "Why wasn't my code found?",
      show: ['codelegacy'],
      content: (
        <>
          <Text
            as="p"
            sx={{
              fontSize: 0,
            }}
          >
            In rare cases your file might not be indexed, for example we don&apos;t index
          </Text>
          <Box as="p" sx={{pl: 3}}>
            <ul>
              <li>Empty files and files over 350 kiB are excluded</li>
              <li>Very large repositories may not be indexed</li>
            </ul>
          </Box>
        </>
      ),
    },
    {
      name: 'Regular expressions',
      show: ['code', 'codelegacy'],
      content: (
        <>
          <TipsContentList
            data={[
              {
                name: 'Sparse followed by index:',
                value: '/sparse.*index/',
              },
              {
                name: `Lines that end with return:`,
                value: '/return;$/',
              },
              {
                name: `File paths matching:`,
                value: (
                  <>
                    <Text sx={{color: 'accent.fg'}}>path:</Text>
                    /^\.github\/workflows\/.*\.yaml$/
                  </>
                ),
              },
            ]}
          />
          <Box
            sx={{
              mt: 2,
              fontSize: 0,
              color: 'fg.muted',
            }}
          >
            Note that you&apos;ll have to escape any slashes in the regex. For more information, see our{' '}
            <Link
              inline
              href="https://docs.github.com/search-github/github-code-search/understanding-github-code-search-syntax"
            >
              syntax guide
            </Link>
            .
          </Box>
        </>
      ),
    },
    {
      name: 'Saved searches',
      show: [
        'code',
        'codelegacy',
        'repositories',
        'issues',
        'pullrequests',
        'commits',
        'discussions',
        'registrypackages',
        'marketplace',
        'topics',
        'wikis',
        'users',
      ],
      content:
        'Always searching within the same organization or set of repositories? Try constructing a query and click the save button in the top right corner.',
    },
  ]
}
