import type React from 'react'
import {Box, Text, useDetails, Details} from '@primer/react'
import {ChevronDownIcon, ChevronUpIcon} from '@primer/octicons-react'

import type {NoResultDataItem} from './data'

export default function Tips({data}: {data: NoResultDataItem[]}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: ['center', 'center', 'center', 'flex-start'],
        mt: [2, 2, 2, 0, 0],
      }}
    >
      <Box
        sx={{
          mt: 3,
          width: '100%',
        }}
      >
        {data.map((t, index) => {
          return (
            <TipItem name={t.name} key={t.name} defaultOpen={index === 0} isLastItem={index + 1 === data.length}>
              {t.content}
            </TipItem>
          )
        })}
      </Box>
    </Box>
  )
}

interface TipItemProps {
  children?: React.ReactNode
  name: string
  defaultOpen: boolean
  isLastItem: boolean
}

function TipItem({children, name, isLastItem, defaultOpen = false}: TipItemProps) {
  const {getDetailsProps, open} = useDetails({closeOnOutsideClick: false})
  const {onToggle, ref, ...detailsProps} = getDetailsProps()

  return (
    <Details
      {...detailsProps}
      ref={ref}
      open={defaultOpen}
      onToggle={onToggle}
      sx={{
        ':first-child summary': {
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        },
        ':last-child summary': {
          borderBottomLeftRadius: isLastItem && open ? 0 : 2,
          borderBottomRightRadius: isLastItem && open ? 0 : 2,
          borderBottomWidth: 1,
        },
      }}
    >
      <Box
        as="summary"
        sx={{
          py: 2,
          userSelect: 'none',
          px: 3,
          height: open ? 41 : 40,
          listStyle: 'none',
          borderColor: 'border.default',
          borderWidth: 1,
          borderBottomWidth: open ? 1 : 0,
          borderStyle: 'solid',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          ':hover': {
            bg: 'canvas.inset',
          },
          '::marker': {
            display: 'none',
          },
          '::-webkit-details-marker': {
            display: 'none',
          },
        }}
      >
        <Text sx={{fontSize: 1, fontWeight: 'semibold', flex: 1}}>{name}</Text>
        {open ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
      </Box>
      {open && (
        <Box
          sx={{
            borderColor: 'border.default',
            borderStyle: 'solid',
            borderTopWidth: 0,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderBottomLeftRadius: isLastItem && open ? 2 : 0,
            borderBottomRightRadius: isLastItem && open ? 2 : 0,
            borderBottomWidth: isLastItem && open ? 1 : 0,
            p: 3,
            fontSize: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Details>
  )
}

try{ Tips.displayName ||= 'Tips' } catch {}
try{ TipItem.displayName ||= 'TipItem' } catch {}