import {Box, Link} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {Fragment} from 'react'
import {RepoIcon} from '@primer/octicons-react'
import type {SearchResponse, Topic, TopicsResults} from '../../types/blackbird-types'
import {Count, CountMode} from '../../../react-shared/Count'
import Result from '../search-result'
import {topicPath} from '@github-ui/paths'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {StarButton} from './StarButton'
import {GitHubAvatar} from '@github-ui/github-avatar'

export default function Topics({results}: {results: TopicsResults & SearchResponse}) {
  return (
    <Result.List>
      {results.results.map(item => (
        <TopicResult
          key={`topic-result-${item.id}`}
          item={item}
          signInPath={!results.logged_in ? results.sign_in_path : ''}
        />
      ))}
    </Result.List>
  )
}

export function TopicResult({item, signInPath}: {item: Topic; signInPath?: string}) {
  return (
    <Result>
      <Result.Header>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {item.logo_url && (
            <Result.Avatar>
              <GitHubAvatar src={item.logo_url} />
            </Result.Avatar>
          )}

          <Result.Title>
            <TopicLink name={item.name} displayName={item.hl_display_name} />
          </Result.Title>

          <StarButton
            sx={{marginLeft: 'auto'}}
            starred={item.starred_by_current_user}
            name={item.name}
            path={topicPath({topicName: item.name})}
            signInPath={signInPath}
          />
        </Box>
      </Result.Header>

      {item.hl_short_description ? (
        <Result.Content>
          <Result.SearchMatchText text={item.hl_short_description} />
        </Result.Content>
      ) : null}
      <Result.Footer>
        <Result.FooterItem>
          <RepositoryCount value={item.repository_count} isLowerBound={item.repository_count_over_max_fetch_limit} />
        </Result.FooterItem>

        {item.related.length === 0 ? null : (
          <Result.FooterItem>
            <RelatedTopics topics={item.related} show={3} />
          </Result.FooterItem>
        )}
      </Result.Footer>
    </Result>
  )
}

function TopicLink({name, displayName}: {name: string; displayName?: SafeHTMLString}) {
  return (
    <Link href={topicPath({topicName: name})}>
      {displayName ? <Result.SearchMatchText text={displayName} /> : <span>{name}</span>}
    </Link>
  )
}

function RepositoryCount({value, isLowerBound}: {value: number; isLowerBound: boolean}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Octicon
        icon={RepoIcon}
        size={16}
        sx={{
          mr: 1,
        }}
      />
      <Count
        ariaLabel={`${value} repositories`}
        value={value}
        mode={isLowerBound ? CountMode.LowerBound : CountMode.Exact}
      />
    </Box>
  )
}

function RelatedTopics({topics, show}: {topics: readonly string[]; show: number}) {
  const head = [...topics].slice(0, show)
  const rest = [...topics].slice(show)

  return (
    <div>
      {head.map((topic, i) => (
        <Fragment key={`related-topic-${topic}`}>
          <TopicLink name={topic} />
          {i !== head.length - 1 ? <span>{', '}</span> : null}
        </Fragment>
      ))}
      {rest.length > 0 ? (
        <span key="related-topics-more">
          {' '}
          and <Count value={rest.length} /> more
        </span>
      ) : null}
    </div>
  )
}

try{ Topics.displayName ||= 'Topics' } catch {}
try{ TopicResult.displayName ||= 'TopicResult' } catch {}
try{ TopicLink.displayName ||= 'TopicLink' } catch {}
try{ RepositoryCount.displayName ||= 'RepositoryCount' } catch {}
try{ RelatedTopics.displayName ||= 'RelatedTopics' } catch {}