import React from 'react'
import {ActionList, Box} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {
  type FacetEntry,
  FacetKindFilter,
  FacetKindLanguage,
  FacetKindPath,
  FacetKindState,
  FacetKindPackageType,
} from '../../types/blackbird-types'
import {useNavigateToQuery} from '../../hooks/use-navigate-to-query'
import LanguageCircle from '../LanguageCircle'
import FacetNegateButton from './FacetNegateButton'
import {packageIconFromName} from '../PackageIcons'
import EllipsisOverflow from '../EllipsisOverflow'
import {GitHubAvatar} from '@github-ui/github-avatar'

import {FileDirectoryIcon, PlusCircleIcon} from '@primer/octicons-react'
import {formatPackageTitle} from '../../utilities/format-type-title'

interface FacetOptionProps {
  autofocus: boolean
  item: FacetEntry
  allowNegation: boolean
  kind: string
  onSelect: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  href?: string
  mobile?: boolean
}

export const HEIGHT_ACTION_LIST_ITEM = 32 // We need this for now until Primer supports trailing buttons

export default function FacetOption({autofocus, kind, item, href, onSelect, allowNegation, mobile}: FacetOptionProps) {
  const navigateToQuery = useNavigateToQuery()

  const negateButtonRef = React.useRef<HTMLButtonElement>(null)
  const linkItemRef = React.useRef<HTMLElement | null>(null)

  let leadingVisual = null
  let itemName = item.name

  switch (kind) {
    case FacetKindLanguage:
      leadingVisual = <LanguageCircle color={item.language_color} />
      break
    case FacetKindPath:
      leadingVisual = <Octicon icon={FileDirectoryIcon} size={16} />
      break
    case FacetKindFilter:
      allowNegation = false
      leadingVisual = <PlusCircleIcon />
      break
    case FacetKindState:
      allowNegation = false
      leadingVisual = item.visual
      break
    case FacetKindPackageType:
      allowNegation = false
      leadingVisual = packageIconFromName(item.name, `_filter_${mobile ? 'mobile' : 'desktop'}`)
      itemName = formatPackageTitle(itemName)
  }

  if (item.owner) {
    leadingVisual = <GitHubAvatar size={16} src={`/${item.owner}.png`} />
  }

  return (
    <ActionList.LinkItem
      as={item.shouldNavigate ? 'a' : 'button'}
      href={item.shouldNavigate ? href : undefined}
      data-react-autofocus={autofocus ? true : null}
      onClick={onSelect}
      onAuxClick={onSelect}
      tabIndex={0}
      onKeyDown={(event: React.KeyboardEvent) => {
        // Not a hothey, but for navigation to the negate button
        // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
        if (event.key === 'ArrowRight') {
          negateButtonRef.current?.focus()
        }
        // Not a hothey, but for navigation to the negate button
        // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
        if (event.key === 'ArrowLeft') {
          linkItemRef.current?.focus()
        }
      }}
      ref={(ref: HTMLElement | null) => {
        linkItemRef.current = ref
      }}
      sx={{
        alignItems: 'center',
        height: HEIGHT_ACTION_LIST_ITEM,
        minWidth: 0,
        p: 0,
        textAlign: 'left',

        '.negate-button': {
          display: 'none',
        },

        '&:is(button)': {
          display: 'flex',
        },

        ':hover .negate-button, :focus .negate-button, :focus-within .negate-button': {
          display: 'block',
        },
      }}
    >
      <ActionList.LeadingVisual sx={{mx: 2, color: 'fg.muted'}}>{leadingVisual}</ActionList.LeadingVisual>
      <Box
        sx={{display: 'flex', alignItems: 'center'}}
        // Tell Google translate to not bother
        className="notranslate"
      >
        <Box sx={{flex: '1', minWidth: 0}}>
          <EllipsisOverflow title={itemName} side="left" sx={{maxWidth: '100%'}}>
            &lrm;{itemName}&lrm;
          </EllipsisOverflow>
        </Box>
        {allowNegation && (
          // eslint-disable-next-line github/a11y-role-supports-aria-props
          <Box
            className="negate-button"
            sx={{width: HEIGHT_ACTION_LIST_ITEM, height: HEIGHT_ACTION_LIST_ITEM, position: 'relative'}}
            aria-label={`Right arrow to exclude ${itemName}`}
          >
            <Box
              sx={{
                display: 'flex', // Important to keep the arrow in the tooltip aligned correctly
              }}
            >
              <FacetNegateButton
                ariaLabel={`Exclude ${itemName}`}
                ref={negateButtonRef}
                onClick={event => {
                  if (!item || event.defaultPrevented) return
                  const languageFilter = `NOT ${item.query}`
                  navigateToQuery(undefined, languageFilter)
                  event.preventDefault()
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ActionList.LinkItem>
  )
}

try{ FacetOption.displayName ||= 'FacetOption' } catch {}