import {Box, CircleBadge, Link} from '@primer/react'
import {Octicon, Tooltip} from '@primer/react/deprecated'
import {DownloadIcon, VerifiedIcon} from '@primer/octicons-react'
import type {MarketplaceResult, MarketplaceResults} from '../../types/blackbird-types'
import {assertNever} from '../../utilities/assert-never'
import {Count} from '../../../react-shared/Count'
import type {PropsWithChildren} from 'react'
import Result from '../search-result'
import TopicLabel from '../search-result/TopicLabel'
import {marketplaceCategoryPath, ownerPath} from '@github-ui/paths'
import {SafeHTMLBox, type SafeHTMLString} from '@github-ui/safe-html'
import {GitHubAvatar} from '@github-ui/github-avatar'

export default function MarketplaceListings({results}: {results: MarketplaceResults}) {
  return (
    <Result.List>
      {results.results.map(item => (
        <MarketplaceResultItem key={`marketplace-result-${item.id}`} item={item} />
      ))}
    </Result.List>
  )
}

export function MarketplaceResultItem({item}: {item: MarketplaceResult}) {
  return (
    <Result>
      <Result.Header>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Result.Avatar>
            <MarketplaceResultAvatar item={item} />
          </Result.Avatar>

          <Result.Title>
            <Link href={item.resource_path}>{item.name}</Link>
          </Result.Title>

          {item.type === 'marketplace_listing' && item.state === 'verified' && (
            <div>
              <Tooltip aria-label="Verified by GitHub">
                <Octicon
                  icon={VerifiedIcon}
                  size={16}
                  sx={{
                    marginLeft: 1,
                    color: 'success.fg',
                  }}
                />
              </Tooltip>
            </div>
          )}
        </Box>
      </Result.Header>

      <Result.Content>
        <span>{item.type === 'marketplace_listing' ? item.short_description : item.description}</span>
      </Result.Content>

      {(item.primary_category || item.secondary_category) && (
        <Result.TokenList>
          {item.primary_category && (
            <TopicLabel href={marketplaceCategoryPath({categoryName: item.primary_category})}>
              {item.primary_category}
            </TopicLabel>
          )}
          {item.secondary_category && (
            <TopicLabel href={marketplaceCategoryPath({categoryName: item.secondary_category})}>
              {item.secondary_category}
            </TopicLabel>
          )}
        </Result.TokenList>
      )}

      <Result.Footer>
        <Result.FooterItem>
          By&nbsp;
          <Link inline href={ownerPath({owner: item.owner_login})}>
            {item.owner_login}
          </Link>
        </Result.FooterItem>
        <Result.FooterItem>
          {item.type === 'marketplace_listing'
            ? 'Application'
            : item.type === 'repository_action'
              ? 'GitHub Action'
              : 'GitHub Stack'}
        </Result.FooterItem>
        {item.type === 'marketplace_listing' && (
          <Result.FooterItem>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Octicon
                icon={DownloadIcon}
                size={16}
                sx={{
                  mr: 1,
                }}
              />
              <Count ariaLabel={`${item.installation_count} downloads`} value={item.installation_count} />
            </Box>
          </Result.FooterItem>
        )}
      </Result.Footer>
    </Result>
  )
}

function MarketplaceResultAvatar({item}: {item: MarketplaceResult}) {
  const avatarSize = 12

  switch (item.type) {
    case 'marketplace_listing':
      return (
        <MarketplaceCircleBadge color={addHashToColor(item.marketplace_listing.listing.bgcolor)}>
          <GitHubAvatar size={avatarSize} src={item.development_logo || item.listing_logo_url} />
        </MarketplaceCircleBadge>
      )
    case 'repository_action':
      return item.icon_svg ? (
        <MarketplaceCircleBadge color={addHashToColor(item.repository_action.repository_action.color)}>
          <MarketplaceSvgAvatar size={avatarSize} svg={item.icon_svg} />
        </MarketplaceCircleBadge>
      ) : null
    case 'repository_stack':
      return item.icon_svg ? (
        <MarketplaceCircleBadge color={addHashToColor(item.repository_stack.repository_stack.color)}>
          <MarketplaceSvgAvatar size={avatarSize} svg={item.icon_svg} />
        </MarketplaceCircleBadge>
      ) : null
    default:
      assertNever(item)
  }
}

function addHashToColor(color: string): string {
  return `#${color}`
}

function MarketplaceCircleBadge({color, children}: PropsWithChildren<{color: string}>) {
  return (
    <CircleBadge
      size={20}
      sx={{
        backgroundColor: color,
        border: '1px solid',
        borderColor: 'border.subtle',
        boxShadow: 'none',
        color: 'white',
      }}
    >
      {children}
    </CircleBadge>
  )
}

function MarketplaceSvgAvatar({size, svg}: {size: number; svg: SafeHTMLString}) {
  return (
    <SafeHTMLBox
      html={svg}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        // Override the size of the SVG
        '> *': {
          height: size,
          width: size,
        },
      }}
    />
  )
}

try{ MarketplaceListings.displayName ||= 'MarketplaceListings' } catch {}
try{ MarketplaceResultItem.displayName ||= 'MarketplaceResultItem' } catch {}
try{ MarketplaceResultAvatar.displayName ||= 'MarketplaceResultAvatar' } catch {}
try{ MarketplaceCircleBadge.displayName ||= 'MarketplaceCircleBadge' } catch {}
try{ MarketplaceSvgAvatar.displayName ||= 'MarketplaceSvgAvatar' } catch {}