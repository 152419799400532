import type {PropsWithChildren} from 'react'
import {Link, type LinkProps} from '@primer/react'
import type {PathFunction, PathParams, Query} from '@github-ui/paths'
import {useSoftNavigateTo} from '../hooks/use-soft-navigate-to'

type ParameterlessSoftLinkProps<T extends PathParams> = Pick<LinkProps, 'sx' | 'onClick'> & {
  path: PathFunction<T>
  query?: Query
}

type SoftLinkProps<T extends PathParams> = PropsWithChildren<
  T extends undefined ? ParameterlessSoftLinkProps<undefined> : ParameterlessSoftLinkProps<T> & T
>

/**
 * A link that performs a soft navigation to the given path when clicked
 */
export function SoftLink<T extends PathParams>({children, path, query, sx, onClick, ...args}: SoftLinkProps<T>) {
  // These type assertions are correct; tsc is choking on separating and recombining the
  // two possible cases for PathFunction, which breaks its inference for the `args` object.
  const [href, navigateToPath] = useSoftNavigateTo(path as PathFunction<T>, args as unknown as T, query)

  return (
    <Link
      href={href}
      onClick={e => {
        onClick?.(e)
        navigateToPath(e)
      }}
      sx={sx}
    >
      {children}
    </Link>
  )
}

try{ SoftLink.displayName ||= 'SoftLink' } catch {}