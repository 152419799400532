import {Ago} from '@github-ui/ago'
import {Box, Button} from '@primer/react'
import {Tooltip} from '@primer/react/deprecated'
import type {
  CheckRun as ServerCheckRun,
  CommitAuthor,
  CommitResultItem,
  CommitsResults,
} from '../../types/blackbird-types'
import React from 'react'
import Result from '../search-result'
// eslint-disable-next-line no-restricted-imports
import {copyText} from '../../../github/command-palette/copy'
import {SignedCommitBadge, type SignatureResult} from '@github-ui/signed-commit-badge'
import {ChecksStatusBadge, type CombinedStatusResult, type CheckRun} from '@github-ui/commit-checks-status'
import {CommitAuthors} from '../search-result/CommitAuthors'
import IssueReferences from '../search-result/IssueReferences'

export default function Commits({results}: {results: CommitsResults}) {
  return (
    <Result.List>
      {results.results.map((item, i) => (
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <CommitResult item={item} key={i} />
      ))}
    </Result.List>
  )
}

function CommitResult({item}: {item: CommitResultItem}) {
  const repositoryName = item.repository.repository.name
  const repositoryOwnerName = item.repository.repository.owner_login
  const repositoryUrl = `${repositoryOwnerName}/${repositoryName}`
  const commitHash = item.sha.slice(0, 7)
  const date = new Date(item.author_date)
  const verificationStatus = item.verification_status
  const statusRollup = item.status_check_rollup?.state
  const signature = item.signature_verification_reason ? createCommitSignature(item) : undefined
  const combinedStatus = createCombinedStatus(item)
  const commitAuthors = createCommitAuthors(item)

  const [copied, setCopied] = React.useState(false)
  const copyCommit = (hash: string) => {
    copyText(hash)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <Result>
      <Result.Header>
        <Result.RepositoryContext name={repositoryName} owner={repositoryOwnerName} />
        <span className="sr-only">:&nbsp;</span>
        <Result.Title>
          {statusRollup && (
            <ChecksStatusBadge variant="circled" statusRollup={statusRollup} combinedStatus={combinedStatus} />
          )}
          <Result.SearchMatchText text={item.hl_subject} />
          <IssueReferences issueRefs={item.issue_references} />
        </Result.Title>
      </Result.Header>

      {item.hl_body && (
        <Result.Content>
          <Result.SearchMatchText text={item.hl_body} />
        </Result.Content>
      )}

      <Result.Footer>
        <Result.FooterItem>
          <CommitAuthors
            commitAuthors={commitAuthors}
            committerAttribution={item.committer_attribution}
            repositoryUrl={repositoryUrl}
            committer={item.committer}
            authorTooltip={item.commit_author_tooltip}
            commitId={item.id}
          />
          <Ago timestamp={date} />
        </Result.FooterItem>

        <Result.FooterItem>
          <Box sx={{pr: [2, 2]}}>
            <Button
              sx={{p: 0, color: 'fg.subtle', fontSize: '12px', fontWeight: 'normal'}}
              onClick={() => copyCommit(item.sha)}
              size="small"
              variant="invisible"
            >
              <Tooltip direction="s" aria-label="Copy full commit hash">
                {copied ? 'Copied!' : commitHash}
              </Tooltip>
            </Button>
          </Box>
          {signature && (
            <SignedCommitBadge
              commitOid={item.sha}
              hasSignature={item.has_signature}
              verificationStatus={verificationStatus}
              signature={signature}
            />
          )}
        </Result.FooterItem>
      </Result.Footer>
    </Result>
  )
}

function createCommitSignature(item: CommitResultItem): SignatureResult {
  return {
    signatureVerificationReason: item.signature_verification_reason.toUpperCase(),
    hasSignature: item.has_signature,
    isViewer: item.is_viewer,
    keyExpired: item.key_expired,
    keyId: item.key_id,
    keyRevoked: item.key_revoked,
    signedByGitHub: item.signed_by_github,
    signerAvatarUrl: item.signer_avatar_url,
    signerLogin: item.signer_login,
    signatureCertificateSubject: item.signature_certificate_subject,
    signatureCertificateIssuer: item.signature_certificate_issuer,
    signatureType: item.signature_type,
    helpUrl: item.help_url,
  }
}

function createCombinedStatus(item: CommitResultItem): CombinedStatusResult {
  return {
    checksStatusSummary: item.checks_status_summary,
    checksHeaderState: item.checks_header_state,
    checkRuns: item.check_runs.map(createCheckRun),
  }
}

function createCommitAuthors(item: CommitResultItem): CommitAuthor[] {
  return item.authors.map(author => ({
    login: author.login,
    avatarUrl: author.avatar_url,
    displayName: author.display_name,
    developmentAvatar: author.development_avatar,
  }))
}

function createCheckRun(serverCheckRun: ServerCheckRun): CheckRun {
  return {
    name: serverCheckRun.name,
    state: serverCheckRun.state,
    description: serverCheckRun.description,
    targetUrl: serverCheckRun.target_url,
    icon: serverCheckRun.icon,
    avatarUrl: serverCheckRun.avatar_url,
    avatarDescription: serverCheckRun.avatar_description,
    avatarLogo: serverCheckRun.avatar_logo,
    additionalContext: serverCheckRun.additional_context,
    pending: serverCheckRun.pending,
    avatarBackgroundColor: serverCheckRun.avatar_background_color,
  }
}

try{ Commits.displayName ||= 'Commits' } catch {}
try{ CommitResult.displayName ||= 'CommitResult' } catch {}