import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import {useCallback} from 'react'
import {useAnalytics} from '@github-ui/use-analytics'
import {useCurrentUser} from '../../react-shared/Repos/CurrentUser'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {getRelativeHref, searchStatsPath} from '@github-ui/paths'
import type {CodeResult} from '../types/blackbird-types'

type SendSearchResultFunction = (queryId: string, position: number, result: CodeResult) => void
type SendSearchBackNavigationFunction = (queryId: string) => void
type SendSearchNextPageNavigationFunction = (queryId: string, page: number) => void

export function useSearchAnalytics(): {
  sendSearchResultClick: SendSearchResultFunction
  sendSearchBackNavigation: SendSearchBackNavigationFunction
  sendSearchNextPageNavigation: SendSearchNextPageNavigationFunction
  sendSampledSearchResult: SendSearchResultFunction
} {
  const {sendAnalyticsEvent} = useAnalytics()
  const sendSearchUIEvent = useSendSearchUIEvent()
  const shouldUseUIEventTable = useFeatureFlag('code_nav_ui_events')

  return {
    sendSearchResultClick: useCallback(
      (queryId, position, result) => {
        sendAnalyticsEvent('search.result-click', '', {
          queryId,
          position,
          path: result.path,
          language: result.language_name,
          line_number: result.line_number,
          ref_name: result.ref_name,
          commit_sha: result.commit_sha,
          blob_sha: result.blob_sha,
          repo_nwo: result.repo_nwo,
        })
        if (shouldUseUIEventTable) {
          sendSearchUIEvent('SEARCH_RESULT.CLICK', 'click', {
            query_id: queryId,
            result_type: 'code', // Currently we only record telemetry for code results
            result_index: position,
            result_path: result.path,
            result_language: result.language_name,
            result_line_number: result.line_number,
            result_ref_name: result.ref_name,
            result_commit_sha: result.commit_sha,
            result_blob_sha: result.blob_sha,
            result_repo_nwo: result.repo_nwo,
          })
        }
      },
      [sendAnalyticsEvent, sendSearchUIEvent, shouldUseUIEventTable],
    ),
    sendSearchBackNavigation: useCallback(
      queryId => {
        sendAnalyticsEvent('search.back', '', {queryId})
        if (shouldUseUIEventTable) {
          sendSearchUIEvent('PREVIOUS_PAGE.CLICK', 'click', {query_id: queryId})
        }
      },
      [sendAnalyticsEvent, sendSearchUIEvent, shouldUseUIEventTable],
    ),
    sendSearchNextPageNavigation: useCallback(
      (queryId, page) => {
        sendAnalyticsEvent('search.next-page', '', {queryId, page})
        if (shouldUseUIEventTable) {
          sendSearchUIEvent('NEXT_PAGE.CLICK', 'click', {query_id: queryId, page_index: page})
        }
      },
      [sendAnalyticsEvent, sendSearchUIEvent, shouldUseUIEventTable],
    ),
    sendSampledSearchResult: useCallback(
      (queryId, position, result) => {
        sendAnalyticsEvent('search.sampled-result', '', {
          queryId,
          position,
          path: result.path,
          language: result.language_name,
          line_number: result.line_number,
          ref_name: result.ref_name,
          commit_sha: result.commit_sha,
          blob_sha: result.blob_sha,
          repo_nwo: result.repo_nwo,
        })
        if (shouldUseUIEventTable) {
          sendSearchUIEvent('SEARCH_RESULT.SAMPLED', 'sampled', {
            query_id: queryId,
            result_type: 'code', // Currently we only record telemetry for code results
            result_index: position,
            result_path: result.path,
            result_language: result.language_name,
            result_line_number: result.line_number,
            result_ref_name: result.ref_name,
            result_commit_sha: result.commit_sha,
            result_blob_sha: result.blob_sha,
            result_repo_nwo: result.repo_nwo,
          })
        }
      },
      [sendAnalyticsEvent, sendSearchUIEvent, shouldUseUIEventTable],
    ),
  }
}

function useSendSearchUIEvent() {
  const statsPath = getRelativeHref(searchStatsPath, undefined)
  const {id, login} = useCurrentUser() || {id: undefined, login: undefined}

  return useCallback(
    (target: SearchUIEventTarget, interaction: Interaction, eventData: SearchEventMetadata) => {
      const method = 'POST'
      const body: SearchUIEvent = {
        target,
        interaction,
        performed_at: Date.now(),
        react_app: 'blackbird-monolith',
        react_page: 'search-results',
        actor_id: id,
        actor_login: login,
        ...eventData,
        ...getBrowserEventMetadata(),
      }

      verifiedFetchJSON(statsPath, {method, body})
    },
    [statsPath, id, login],
  )
}

type EventContext = Record<string, string | number | boolean>

type SearchUIEventTarget = 'SEARCH_RESULT.CLICK' | 'NEXT_PAGE.CLICK' | 'PREVIOUS_PAGE.CLICK' | 'SEARCH_RESULT.SAMPLED'

type Interaction = 'click' | 'keydown' | 'stats' | 'sampled'

interface SearchUIEvent {
  target: SearchUIEventTarget
  interaction: Interaction
  performed_at: number
  context?: EventContext
  query_id?: string
  page_index?: number
  result_index?: number
  result_type?: string
  result_path?: string
  result_language?: string
  result_line_number?: number
  result_ref_name?: string
  result_commit_sha?: string
  result_blob_sha?: string
  result_repo_nwo?: string
  react_app: 'blackbird-monolith'
  react_page: 'home' | 'search-results'
  actor_id?: number
  actor_login?: string
  url: string
  user_agent: string
  browser_width: number
  browser_languages: string
}

type BrowserEventMetadata = Pick<SearchUIEvent, 'url' | 'user_agent' | 'browser_width' | 'browser_languages'>

function getBrowserEventMetadata(): BrowserEventMetadata {
  return {
    url: window.location.href,
    user_agent: window.navigator.userAgent,
    browser_width: window.innerWidth,
    browser_languages: window.navigator.languages.join(','),
  }
}

type SearchEventMetadata = Pick<
  SearchUIEvent,
  | 'query_id'
  | 'page_index'
  | 'result_index'
  | 'result_type'
  | 'result_path'
  | 'result_path'
  | 'result_language'
  | 'result_ref_name'
  | 'result_commit_sha'
  | 'result_blob_sha'
  | 'result_repo_nwo'
  | 'result_line_number'
>
