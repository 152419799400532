import {useState} from 'react'
import {Portal, Box, Link, TextInput} from '@primer/react'
import {Dialog} from '@primer/react/experimental'

import {getBlackbirdExperiments, setBlackbirdExperiments} from '../../../../components/search/experiments'

const experimentsQuery = 'repo:github/blackbird-mw "var StaffEnabled = Experiments"'

export const ExperimentsDialog = ({onDismiss, onSave}: {onDismiss: () => void; onSave: () => void}): JSX.Element => {
  const [experiments, setExperiments] = useState(() => getBlackbirdExperiments().join(','))
  const [statusMessage, setStatusMessage] = useState<string | undefined>(undefined)
  const saveExperiments = () => {
    const experimentsArray = []
    for (const pair of experiments.split(',')) {
      if (pair.trim() === '') continue

      if (pair.split('=').length !== 2) {
        setStatusMessage('Invalid experiment key=value pair')
        return
      }
      experimentsArray.push(pair)
    }

    setBlackbirdExperiments(experimentsArray)
    onSave()
    onDismiss()
  }

  return (
    <Portal>
      <Dialog onClose={onDismiss} title={'Experiments'} width="xlarge">
        <Dialog.Body>
          <Box sx={{p: 3}}>
            <span>
              List experiments as comma separated <code>key=value</code> pairs. To see available experiments,{' '}
              <Link inline target="_blank" href={`/search?q=${encodeURIComponent(experimentsQuery)}`}>
                read this code
              </Link>
              .
            </span>
            <TextInput
              sx={{width: '100%', my: 2}}
              aria-label="Experiments"
              name="experiments"
              value={experiments}
              onChange={e => setExperiments(e.target.value)}
              onKeyDown={e => {
                // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
                if (e.key === 'Enter') {
                  saveExperiments()
                }
              }}
            />
            <span>{statusMessage}</span>
          </Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Buttons
            buttons={[
              {type: 'button', onClick: onDismiss, content: 'Cancel'},
              {type: 'submit', onClick: saveExperiments, content: 'Save', buttonType: 'primary'},
            ]}
          />
        </Dialog.Footer>
      </Dialog>
    </Portal>
  )
}

try{ ExperimentsDialog.displayName ||= 'ExperimentsDialog' } catch {}