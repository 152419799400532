import {useLocation} from 'react-router-dom'
import {ActionList, ActionMenu, Text} from '@primer/react'
import {searchPath} from '@github-ui/paths'
import {useSoftNavigateTo} from '../hooks/use-soft-navigate-to'
import {FocusHintPrefix, DefaultSortItem, type SortItem} from '../types/blackbird-types'
import {type FocusHintContextType, useFocusHintContext} from '@github-ui/focus-hint-context'

const SortMenuFocusHint = `${FocusHintPrefix}blackbird-sort-menu`

export default function SortMenu({sortItems, onlyGroup}: {sortItems: SortItem[]; onlyGroup: boolean}) {
  const {search} = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const resultsSort = urlSearchParams.get('s') || DefaultSortItem.sort
  const resultsOrder = urlSearchParams.get('o') || DefaultSortItem.order
  const itemSelectedIndex = sortItems.findIndex(item => item.sort === resultsSort && item.order === resultsOrder) || 0
  const labelText = sortItems[itemSelectedIndex]?.label ?? ''
  const {focusHint, setFocusHint} = useFocusHintContext()

  const items = sortItems.map(({label, sort, order}, index) => (
    <SortMenuItem
      key={`${sort}-${order}`}
      label={label}
      sort={sort}
      order={order}
      isSelected={itemSelectedIndex === index}
      setFocusHint={setFocusHint}
    />
  ))

  if (onlyGroup) {
    return (
      <ActionList.Group selectionVariant="single" sx={{display: ['block', 'block', 'none']}}>
        <ActionList.GroupHeading>Sort by</ActionList.GroupHeading>
        {items}
      </ActionList.Group>
    )
  }

  return (
    <ActionMenu>
      <ActionMenu.Button
        data-testid="sort-button"
        size="small"
        sx={{bg: 'btn.bg'}}
        data-react-autofocus={focusHint === SortMenuFocusHint ? true : null}
      >
        <Text sx={{color: 'fg.muted'}}>Sort by: </Text> {labelText}
      </ActionMenu.Button>

      <ActionMenu.Overlay align="end" width="small">
        <ActionList selectionVariant="single">{items}</ActionList>
      </ActionMenu.Overlay>
    </ActionMenu>
  )
}

function SortMenuItem({
  label,
  sort,
  order,
  isSelected,
  setFocusHint,
}: {
  label: string
  sort: string
  order: string
  isSelected: boolean
  setFocusHint: FocusHintContextType['setFocusHint']
}) {
  const [, navigateToSortedResults] = useSoftNavigateTo(searchPath, undefined, {
    p: null,
    s: sort,
    o: order,
  })

  return (
    <ActionList.Item
      onSelect={() => {
        setFocusHint(SortMenuFocusHint)
        navigateToSortedResults()
      }}
      selected={isSelected}
    >
      {label}
    </ActionList.Item>
  )
}

try{ SortMenu.displayName ||= 'SortMenu' } catch {}
try{ SortMenuItem.displayName ||= 'SortMenuItem' } catch {}