import {Box, Link, type LinkProps} from '@primer/react'
import {repositoryPath} from '@github-ui/paths'

interface RepositoryContextProps {
  owner: string
  name: string
}

export function RepositoryContext(props: RepositoryContextProps) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', fontSize: 0, mb: 1}}>
      <RepositoryLink {...props} sx={{color: 'fg.muted'}} />
    </Box>
  )
}

type RepositoryLinkProps = RepositoryContextProps & LinkProps

export function RepositoryLink({owner, name, ...linkProps}: RepositoryLinkProps) {
  return (
    <Link
      href={repositoryPath({owner, repo: name})}
      data-hovercard-url={repositoryPath({owner, repo: name, action: 'hovercard'})}
      {...linkProps}
    >
      <span>{`${owner}/${name}`}</span>
    </Link>
  )
}

try{ RepositoryContext.displayName ||= 'RepositoryContext' } catch {}
try{ RepositoryLink.displayName ||= 'RepositoryLink' } catch {}