import {Ago} from '@github-ui/ago'
import {Box, Link, Label} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import type {RepositoriesResults, RepositoryResultItem, SearchResponse} from '../../types/blackbird-types'
import {StarIcon} from '@primer/octicons-react'

import {Count} from '../../../react-shared/Count'
import React, {useState} from 'react'
import Result from '../search-result'
import TopicLabel from '../search-result/TopicLabel'
import TokenList from '../search-result/TokenList'
import {useVerifiedFetch} from '@github-ui/use-verified-fetch'
import {repositoryPath, topicPath} from '@github-ui/paths'
import LanguageCircle from '../LanguageCircle'
import {SafeHTMLBox, type SafeHTMLString} from '@github-ui/safe-html'
import {StarButton} from './StarButton'
import {GitHubAvatar} from '@github-ui/github-avatar'

function Repositories({results}: {results: RepositoriesResults & SearchResponse}) {
  const [sponsorButtonMap, setSponsorButtonMap] = React.useState<{[key: string]: SafeHTMLString}>()

  const getSponsors = useVerifiedFetch<{[key: string]: SafeHTMLString}>(
    '/sponsors/batch_deferred_sponsor_buttons',
    'post',
  )

  // Scope funding files to the repo as many repos in the same org can have different funding files
  const getRepoKey = (item: RepositoryResultItem) =>
    item.has_funding_file
      ? `${item.repo.repository.owner_id}-${item.repo.repository.id}`
      : `${item.repo.repository.owner_id}`

  React.useEffect(() => {
    async function populateSponsorButtonMap(formData: FormData) {
      const sponsorResponse = await getSponsors({body: formData})

      if (sponsorResponse.ok) {
        const sponsorButtons = await sponsorResponse.json()

        setSponsorButtonMap(sponsorButtons)
      }
    }

    const sponsorFormData = new FormData()

    for (const result of results.results) {
      const repository = result.repo.repository

      const formRepoKey = `items[item-${getRepoKey(result)}]`

      if ((result.sponsorable || result.has_funding_file) && !sponsorFormData.has(`${formRepoKey}[sponsorable_id]`)) {
        if (!sponsorFormData.has('_method')) {
          sponsorFormData.set('_method', 'GET')
        }

        sponsorFormData.set(`${formRepoKey}[sponsorable_id]`, repository.owner_id)
        sponsorFormData.set(`${formRepoKey}[sponsorable_login]`, repository.owner_login)
        sponsorFormData.set(`${formRepoKey}[repo_name]`, repository.name)
        sponsorFormData.set(`${formRepoKey}[has_funding_file]`, `${result.has_funding_file}`)
        sponsorFormData.set(`${formRepoKey}[location]`, 'REPO_SEARCH_RESULT_SPONSOR')
      }
    }

    if (sponsorFormData.has('_method')) {
      populateSponsorButtonMap(sponsorFormData)
    }
  }, [results, getSponsors])
  return (
    <Result.List>
      {results.results?.map(item => {
        let sponsorButtonHtml: SafeHTMLString | undefined
        if (sponsorButtonMap) {
          sponsorButtonHtml = sponsorButtonMap[`item-${getRepoKey(item)}`]
        }

        return (
          <RepositoryResult
            key={item.repo.repository.id}
            item={item}
            sponsorButtonHtml={sponsorButtonHtml}
            signInPath={!results.logged_in ? results.sign_in_path : ''}
          />
        )
      })}
    </Result.List>
  )
}

function RepositoryResult({
  item,
  sponsorButtonHtml,
  signInPath,
}: {
  item: RepositoryResultItem
  sponsorButtonHtml?: SafeHTMLString
  signInPath?: string
}) {
  const repository = item.repo.repository

  const repositoryName = repository.name
  const repositoryOwnerName = repository.owner_login
  const repositoryOwnerAvatarUrl = repository.development_avatar || `${repositoryOwnerName}.png`
  const repositoryUrl = `/${repositoryOwnerName}/${repositoryName}`

  const updatedAt = new Date(repository.updated_at)
  const maxVisibleTopics = 5

  const [itemCount, setItemCount] = useState(item.followers)

  return (
    <Result>
      <Box sx={{display: 'flex'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, minWidth: 0}}>
          <Result.Header>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Result.Avatar>
                <GitHubAvatar square={item.owned_by_organization} size={20} src={repositoryOwnerAvatarUrl} />
              </Result.Avatar>
              <Result.Title>
                <Link href={repositoryUrl}>
                  <Result.SearchMatchText text={item.hl_name} sx={{overflow: 'hidden', textOverflow: 'ellipsis'}} />
                </Link>
              </Result.Title>
              {(!item.public || item.archived) && (
                <Label sx={{ml: 2}} variant={item.archived ? 'attention' : undefined}>
                  {item.type}
                </Label>
              )}
            </Box>
          </Result.Header>

          {item.hl_trunc_description && (
            <Result.Content>
              <Result.SearchMatchText text={item.hl_trunc_description} />
            </Result.Content>
          )}

          {item.topics.length !== 0 && (
            <TokenList>
              {item.topics.slice(0, maxVisibleTopics).map(topic => (
                <TopicLabel key={topic} href={topicPath({topicName: topic})}>
                  {topic}
                </TopicLabel>
              ))}
            </TokenList>
          )}

          <Result.Footer>
            {item.language && (
              <Result.FooterItem>
                <Box sx={{mr: 2}}>
                  <LanguageCircle color={item.color} />
                </Box>
                {/* eslint-disable-next-line github/a11y-role-supports-aria-props */}
                <span aria-label={`${item.language} language`}>{item.language}</span>
              </Result.FooterItem>
            )}
            <Result.FooterItem>
              <Link
                href={`${repositoryUrl}/stargazers`}
                aria-label={`${item.followers} stars`}
                sx={{
                  // Simulating color change and underline for icon
                  ':hover': {
                    boxShadow: 'inset 0 -1px 0 0',
                    '> *': {
                      color: 'accent.fg',
                    },
                  },
                  display: 'flex',
                  alignItems: 'center',
                  color: 'fg.muted',
                }}
              >
                <Octicon
                  icon={StarIcon}
                  aria-hidden
                  size={16}
                  sx={{
                    color: 'fg.subtle',
                    mr: '6px',
                  }}
                />
                <Count value={itemCount} />
              </Link>
            </Result.FooterItem>
            <Result.FooterItem>
              <span>
                Updated <Ago timestamp={updatedAt} />
              </span>
            </Result.FooterItem>
          </Result.Footer>
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', minWidth: 0}}>
          <Box sx={{pt: 1, pl: 2}}>
            <StarButton
              starred={item.starred_by_current_user}
              name={item.name}
              path={repositoryPath({owner: item.repo.repository.owner_login, repo: item.repo.repository.name})}
              onToggleCallback={starred => {
                if (starred) {
                  // item.followers doesn't update with any local changes so if the original amount included
                  // this user, we just reset to that if it's re-starred
                  // else, we need to add 1
                  setItemCount(item.starred_by_current_user ? item.followers : item.followers + 1)
                } else {
                  //// item.followers doesn't update with any local changes so if the original amount included
                  // this user, we will need to take off 1 but if it didn't, we just reset to the original
                  setItemCount(item.starred_by_current_user ? item.followers - 1 : item.followers)
                }
              }}
              signInPath={signInPath}
            />
          </Box>
          {(item.sponsorable || item.has_funding_file) && sponsorButtonHtml && (
            <Box
              sx={{
                display: 'flex',
                pt: 1,
                pl: 2,
              }}
            >
              <SafeHTMLBox html={sponsorButtonHtml} />
            </Box>
          )}
        </Box>
      </Box>
    </Result>
  )
}

export default Repositories

try{ Repositories.displayName ||= 'Repositories' } catch {}
try{ RepositoryResult.displayName ||= 'RepositoryResult' } catch {}