import {App} from './App'
import Search from './pages/Search'
import {registerReactAppFactory} from '@github-ui/react-core/register-app'
import {TransitionType} from '@github-ui/react-core/app-routing-types'
import {jsonRoute} from '@github-ui/react-core/json-route'

registerReactAppFactory('blackbird-search', () => ({
  App,
  routes: [
    jsonRoute({path: '/search', Component: Search, transitionType: TransitionType.TRANSITION_WHILE_FETCHING}),
    jsonRoute({
      path: '/:user/:repo/search',
      Component: Search,
      transitionType: TransitionType.TRANSITION_WHILE_FETCHING,
    }),
  ],
}))
