import FeaturedTopic from './FeaturedTopic'
import type {SearchResults} from '../../types/blackbird-types'

export default function PrimarySuggestions({payload}: {payload: SearchResults}) {
  if (payload.type === 'repositories' && payload.topics) {
    return <FeaturedTopic topic={payload.topics.curated_topic} linkedTopic={payload.topics?.linked_topic_name} />
  }

  return null
}

try{ PrimarySuggestions.displayName ||= 'PrimarySuggestions' } catch {}