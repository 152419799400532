import Footer, {FooterItem} from './Footer'
import Header, {Avatar, OverflowMenu, Title} from './Header'
import Content from './Content'
import {RepositoryContext, RepositoryLink} from './RepositoryContext'
import Result from './Result'
import SearchMatchText from './SearchMatchText'
import TokenList from './TokenList'
import List from './List'

// Expose subcomponents as properties on the top-level Result component so that
// they can be used like this with only a single import:
// ```
// <Result>
//   <Result.Header>
//     <Result.Title>Title</Result.Title>
//   </Result.Header>
// </Result>
// ```
const pieces = {
  Avatar,
  Content,
  Footer,
  FooterItem,
  Header,
  List,
  OverflowMenu,
  RepositoryContext,
  RepositoryLink,
  SearchMatchText,
  Title,
  TokenList,
}
Object.assign(Result, pieces)

// This type assertion ensures that autocomplete will pick up the subcomponents like <Result.Content/>
export default Result as typeof Result & typeof pieces
