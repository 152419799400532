import {Box, Link, Text} from '@primer/react'
import {Tooltip} from '@primer/react/deprecated'
import React from 'react'
import type {Author, CommitAuthor} from '../../types/blackbird-types'
import {GitHubAvatar} from '@github-ui/github-avatar'

/*
 * This component is responsible for rendering the commit authors, logic the same as attribution_info_component.html.erb
 */
export function CommitAuthors({
  commitAuthors,
  committerAttribution,
  repositoryUrl,
  committer,
  authorTooltip,
  commitId,
}: {
  commitAuthors: CommitAuthor[]
  committerAttribution: boolean
  repositoryUrl: string
  committer: Author
  authorTooltip: string
  commitId: string
}) {
  const getCommitAuthorsList = () => {
    const commitAuthorsList = []
    for (const author of commitAuthors) {
      const authorUrl = author.login ? `${repositoryUrl}/commits?author=${author.login}` : undefined
      const authorName = author.login ?? author.displayName
      const lastIndex = commitAuthors.findIndex(a => a === author) === commitAuthors.length - 1

      commitAuthorsList.push(
        <React.Fragment key={`${commitId}-${authorName}`}>
          <GitHubAvatar
            size={16}
            src={author.developmentAvatar || author.avatarUrl}
            sx={{
              overflow: 'visible',
            }}
          />
          &nbsp;
          {authorUrl ? (
            <Link href={`/${authorUrl}`}>
              <Text sx={{color: 'fg.muted', fontWeight: 'semibold'}}>{authorName}&nbsp;</Text>
            </Link>
          ) : (
            <Text sx={{color: 'fg.muted', fontWeight: 'semibold'}}>{authorName}&nbsp;</Text>
          )}
          {!lastIndex && <>and&nbsp;</>}
        </React.Fragment>,
      )
    }
    return commitAuthorsList
  }

  const committerUrl = committer.login ? `${repositoryUrl}/commits?author=${committer.login}` : undefined

  return (
    <Box sx={{flexWrap: 'wrap', display: 'flex', alignItems: 'center'}}>
      {commitAuthors.length === 1 || (commitAuthors.length === 2 && !committerAttribution) ? (
        getCommitAuthorsList()
      ) : (
        <Tooltip text={authorTooltip} direction="e">
          <Text sx={{fontWeight: 'bold', pr: 1}}>{commitAuthors.length} people</Text>
        </Tooltip>
      )}
      {!committerAttribution ? (
        <span>committed&nbsp;</span>
      ) : (
        <>
          <span>authored and&nbsp;</span>
          <GitHubAvatar
            size={16}
            src={committer.development_avatar || committer.avatar_url}
            sx={{
              mr: 1,
            }}
          />
          {committerUrl ? (
            <Link href={`/${committerUrl}`}>
              <Text sx={{color: 'fg.muted', fontWeight: 'semibold'}}>{committer.login}&nbsp;</Text>
            </Link>
          ) : (
            <Text sx={{color: 'fg.muted', fontWeight: 'semibold'}}>{committer.display_name}&nbsp;</Text>
          )}
          <span>committed&nbsp;</span>
        </>
      )}
    </Box>
  )
}

try{ CommitAuthors.displayName ||= 'CommitAuthors' } catch {}