import type React from 'react'
import type {SearchResults, SearchResultsType} from '../../types/blackbird-types'
import {Box, Text} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {LightBulbIcon} from '@primer/octicons-react'

interface Tip {
  content: React.ReactChild
  show: SearchResultsType[]
}

const tips: Tip[] = [
  {
    content: (
      <>
        Press the{' '}
        <Box sx={{display: 'inline-block'}} className="hx_kbd">
          /
        </Box>{' '}
        key to activate the search input again and adjust your query.
      </>
    ),
    show: [
      'code',
      'codelegacy',
      'repositories',
      'commits',
      'issues',
      'pullrequests',
      'discussions',
      'registrypackages',
      'topics',
      'wikis',
      'users',
      'marketplace',
    ],
  },
  {
    content: 'Restrict your search to the title by using the in:title qualifier.',
    show: ['issues', 'pullrequests'],
  },
]

export default function MiniTip({payload}: {payload: SearchResults}) {
  const type = payload.type
  const tipsForType = tips.filter(tip => tip.show.includes(type))
  const randomTip = tipsForType[Math.floor(Math.random() * tipsForType.length)]

  if (!randomTip) {
    return null
  }
  return (
    <Box sx={{fontSize: 0, color: 'fg.muted', p: [3, 3, 0, 0]}}>
      <Box sx={{display: 'inline-block', color: 'attention.fg'}}>
        <Octicon icon={LightBulbIcon} size={16} sx={{color: 'attention.fg'}} />
        <Text sx={{pl: 1, fontWeight: 'bold'}}>ProTip!</Text>&nbsp;
      </Box>
      <span>{randomTip.content}</span>
    </Box>
  )
}

try{ MiniTip.displayName ||= 'MiniTip' } catch {}