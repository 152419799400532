import {useEffect} from 'react'
import {useNavigateToQuery} from '../hooks/use-navigate-to-query'
import {useLocation} from 'react-router-dom'

/**
 * An event dispatched from the search bar to check if our app is mounted
 */
const retransmitEvent = 'blackbird_monolith_retransmit_react'
/**
 * An event which we dispatch in response to the retransmit event to confirm that our app is mounted
 */
const connectedEvent = 'blackbird_monolith_react_connected'

/**
 * An event dispatched when react DISCONNECTS from the page. This can happen e.g. during a turbo page load
 */
const disconnectedEvent = 'blackbird_monolith_react_disconnected'
/**
 * An event dispatched from the search bar to indicate that a search has been initiated
 */
const searchEvent = 'blackbird_monolith_search'
/**
 * An event which we dispatch to inform the search bar that it should update its contents
 */
const inputUpdateEvent = 'blackbird_monolith_update_input'

const setGlobalNavVisibilityEvent = 'blackbird_monolith_set_global_nav_visibility'

const provideFeedbackEvent = 'blackbird_provide_feedback'

/**
 * This hook handles transmitting events between the search bar and this react app to
 * confirm that the app is mounted and to respond to searches initiated in the search bar.
 */
export function useSearchBarEvents(): void {
  // Setup React Search Bar
  useEffect(() => {
    // If the search bar renders after us, it will check if react is loaded. Need to respond
    // with the right custom event.
    function handleRetransmitReactConnection() {
      window.dispatchEvent(new CustomEvent(connectedEvent))

      // Hide the global nav while in the search UI
      window.dispatchEvent(new CustomEvent(setGlobalNavVisibilityEvent, {detail: false}))
    }

    // Emit a custom event to tell the search bar that we're connected
    handleRetransmitReactConnection()
    window.addEventListener(retransmitEvent, handleRetransmitReactConnection)
    return () => {
      // Restore the global nav to the visible state
      window.dispatchEvent(new CustomEvent(setGlobalNavVisibilityEvent, {detail: true}))
      window.removeEventListener(retransmitEvent, handleRetransmitReactConnection)
      window.dispatchEvent(new CustomEvent(disconnectedEvent))
    }
  }, [])

  // Handle Search
  const navigateToQuery = useNavigateToQuery()

  useEffect(() => {
    function handleSearchEvent(e: Event) {
      if (!(e instanceof CustomEvent)) return

      navigateToQuery(e.detail.search, undefined, e.detail.searchParams)
    }

    window.addEventListener(searchEvent, handleSearchEvent)
    return () => {
      window.removeEventListener(searchEvent, handleSearchEvent)
    }
  }, [navigateToQuery])

  const {search} = useLocation()
  const query = new URLSearchParams(search).get('q') ?? ''
  useEffect(() => {
    updateSearchBar(query)
  }, [query])
}

/**
 * Update the search bar's contents based on the new search.
 */
export function updateSearchBar(search: string): void {
  window.dispatchEvent(new CustomEvent<string>(inputUpdateEvent, {detail: search}))
}

/**
 * Trigger the "provide feedback" dialog
 */
export function provideFeedback(): void {
  window.dispatchEvent(new CustomEvent<string>(provideFeedbackEvent))
}
