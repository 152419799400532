import {Button, type ButtonProps} from '@primer/react'
import {useCallback, useState} from 'react'
import {StarFillIcon, StarIcon} from '@primer/octicons-react'
import {useVerifiedFetch} from '@github-ui/use-verified-fetch'

interface StarButtonProps extends Omit<ButtonProps, 'onClick' | 'leadingIcon' | 'children'> {
  path: string
  starred: boolean
  onToggleCallback?: (starred: boolean) => void
  signInPath?: string
}

export function StarButton({path, starred, onToggleCallback, signInPath, ...buttonProps}: StarButtonProps) {
  const [currentlyStarred, setCurrentlyStarred] = useState(starred)
  const star = useVerifiedFetch<void>(`${path}/star`, 'post')
  const unstar = useVerifiedFetch<void>(`${path}/unstar`, 'post')

  const onToggle = useCallback(async () => {
    const action = currentlyStarred ? unstar : star
    setCurrentlyStarred(!currentlyStarred)
    const res = await action()
    if (!res.ok) {
      // Starring failed, so revert back to the previous state
      setCurrentlyStarred(currentlyStarred)
      return
    }
    onToggleCallback?.(!currentlyStarred)
  }, [currentlyStarred, onToggleCallback, star, unstar])

  return signInPath ? (
    <Button size="small" leadingVisual={StarIcon} {...buttonProps} as="a" href={signInPath}>
      {currentlyStarred ? 'Unstar' : 'Star'}
    </Button>
  ) : (
    <Button size="small" onClick={onToggle} leadingVisual={currentlyStarred ? StarFillIcon : StarIcon} {...buttonProps}>
      {currentlyStarred ? 'Unstar' : 'Star'}
    </Button>
  )
}

try{ StarButton.displayName ||= 'StarButton' } catch {}