import type React from 'react'
import {Box, Heading, Text, Link} from '@primer/react'
import {useSoftNavigateTo} from '../../hooks/use-soft-navigate-to'
import {searchPath} from '@github-ui/paths'
import type {SearchKind, SearchKindCount} from '../../hooks/use-search-result-counts'
import {getValueText} from '../../../react-shared/Count'

export default function Header({
  resultKind,
  searchKinds,
  isLoadingCounters,
}: {
  resultKind: SearchKind
  searchKinds: SearchKindCount[]
  isLoadingCounters: boolean
}) {
  let description: React.ReactNode = `Try one of the tips below to find more ${resultKind.readableNamePlural}`

  if (!isLoadingCounters) {
    const withResults = searchKinds.filter(kind => kind.name !== resultKind.name && kind.total && kind.total > 0)
    withResults.sort(function (a, b) {
      return (b.total || 0) - (a.total || 0)
    })
    let resultsInfo

    switch (withResults.length) {
      case 0:
        resultsInfo = null
        break
      case 1:
        resultsInfo = <TabSwitcherLink key={withResults[0]!.name} kind={withResults[0]!} />
        break
      case 2:
        resultsInfo = (
          <>
            <TabSwitcherLink key={withResults[0]!.name} kind={withResults[0]!} /> and{' '}
            <TabSwitcherLink key={withResults[1]!.name} kind={withResults[1]!} />
          </>
        )
        break
      default:
        resultsInfo = (
          <>
            <TabSwitcherLink key={withResults[0]!.name} kind={withResults[0]!} /> and{' '}
            <TabSwitcherLink key={withResults[1]!.name} kind={withResults[1]!} />
          </>
        )
    }

    if (resultsInfo) {
      description = (
        <>However we found {resultsInfo} that matched your search query. Alternatively try one of the tips below.</>
      )
    } else {
      description = <>You could try one of the tips below.</>
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'column', 'row'],
        alignItems: 'center',
      }}
    >
      <Box sx={{mt: [2, 2, 2, 0], pb: 1}}>
        <Heading
          as="h3"
          sx={{
            fontSize: [2, 2, 3, 3, 3],
            textAlign: ['center', 'center', 'center', 'center', 'left'],
          }}
        >
          Your search did not match any {resultKind.readableNamePlural}
        </Heading>
        <Text
          as="p"
          sx={{
            mt: 2,
            display: 'block',
            fontSize: [1, 1, 1, 1, 1],
            color: 'fg.muted',
            textAlign: ['center', 'center', 'center', 'center', 'left'],
          }}
        >
          {description}
        </Text>
      </Box>
    </Box>
  )
}

function TabSwitcherLink({kind}: {kind: SearchKindCount}) {
  const [searchTypeHref, navigateToSearchType] = useSoftNavigateTo(searchPath, undefined, {
    p: null,
    type: kind.name,
    l: null,
    s: null,
    o: null,
  })
  let kindName = kind.total === 1 ? kind.readableName : kind.readableNamePlural

  if (kindName === 'code') {
    kindName = kind.total === 1 ? 'code result' : 'code results'
  }

  return (
    <Link onClick={(e: React.SyntheticEvent) => navigateToSearchType(e)} href={searchTypeHref}>
      {getValueText(kind.total || 0)} {kindName.toLowerCase()}
    </Link>
  )
}

try{ Header.displayName ||= 'Header' } catch {}
try{ TabSwitcherLink.displayName ||= 'TabSwitcherLink' } catch {}