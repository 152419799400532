import type {SearchTypeURLParameter} from '../../../../../../components/search/parsing/parsing'
import type {FacetEntry} from '../../../types/blackbird-types'

export function getFilters(type: SearchTypeURLParameter): FacetEntry[] {
  switch (type) {
    case 'code':
    case 'codelegacy':
      return [
        {
          name: 'Owner',
          query: 'owner:',
        },
        {
          name: 'Symbol',
          query: 'symbol:',
        },
        {
          name: 'Exclude archived',
          query: 'NOT is:archived',
          shouldNavigate: true,
        },
      ]
    case 'repositories':
      return [
        {
          name: 'Owner',
          query: 'owner:',
        },
        {
          name: 'Size',
          query: 'size:',
        },
        {
          name: 'Number of followers',
          query: 'followers:',
        },
        {
          name: 'Number of forks',
          query: 'forks:',
        },
        {
          name: 'Number of stars',
          query: 'stars:',
        },
        {
          name: 'Date created',
          query: 'created:',
        },
        {
          name: 'Date pushed',
          query: 'pushed:',
        },
        {
          name: 'Topic',
          query: 'topic:',
        },
        {
          name: 'License',
          query: 'license:',
        },
        {
          name: 'Archived',
          query: 'archived:',
        },
        {
          name: 'Public',
          query: 'is:public',
          shouldNavigate: true,
        },
        {
          name: 'Private',
          query: 'is:private',
          shouldNavigate: true,
        },
      ]
    case 'issues':
      return [
        {
          name: 'Owner',
          query: 'owner:',
        },
        {
          name: 'State',
          query: 'state:',
        },
        {
          name: 'Close reason',
          query: 'reason:',
        },
        {
          name: 'Has linked pull request',
          query: 'linked:pr',
          shouldNavigate: true,
        },
        {
          name: 'Author',
          query: 'author:',
        },
        {
          name: 'Assignee',
          query: 'assignee:',
        },
        {
          name: 'Mentioned user',
          query: 'mentions:',
        },
        {
          name: 'Mentioned team',
          query: 'team:',
        },
        {
          name: 'Commenter',
          query: 'commenter:',
        },
        {
          name: 'Involved user',
          query: 'involves:',
        },
        {
          name: 'Label',
          query: 'label:',
        },
        {
          name: 'Milestone',
          query: 'milestone:',
        },
        {
          name: 'Number of comments',
          query: 'comments:',
        },
        {
          name: 'Number of interactions',
          query: 'interactions:',
        },
      ]
    case 'pullrequests':
      return [
        {
          name: 'Owner',
          query: 'owner:',
        },
        {
          name: 'State',
          query: 'state:',
        },
        {
          name: 'CI status',
          query: 'status:',
        },
        {
          name: 'Review status',
          query: 'review:',
        },
        {
          name: 'Close reason',
          query: 'reason:',
        },
        {
          name: 'Author',
          query: 'author:',
        },
        {
          name: 'Assignee',
          query: 'assignee:',
        },
        {
          name: 'Mentioned user',
          query: 'mentions:',
        },
        {
          name: 'Mentioned team',
          query: 'team:',
        },
        {
          name: 'Commenter',
          query: 'commenter:',
        },
        {
          name: 'Involved user',
          query: 'involves:',
        },
        {
          name: 'Label',
          query: 'label:',
        },
        {
          name: 'Milestone',
          query: 'milestone:',
        },
        {
          name: 'Number of comments',
          query: 'comments:',
        },
        {
          name: 'Number of interactions',
          query: 'interactions:',
        },
        {
          name: 'Merged',
          query: 'is:merged',
          shouldNavigate: true,
        },
        {
          name: 'Not merged',
          query: 'is:unmerged',
          shouldNavigate: true,
        },
      ]
    case 'commits':
      return [
        {
          name: 'Organization',
          query: 'org:',
        },
        {
          name: 'Author',
          query: 'author:',
        },
        {
          name: 'Committer',
          query: 'committer:',
        },
        {
          name: 'Author email',
          query: 'author-email:',
        },
        {
          name: 'Committer email',
          query: 'committer-email:',
        },
        {
          name: 'Merge commits',
          query: 'merge:',
        },
        {
          name: 'Hash',
          query: 'hash:',
        },
        {
          name: 'Parent hash',
          query: 'parent:',
        },
        {
          name: 'Tree hash',
          query: 'tree:',
        },
        {
          name: 'Public',
          query: 'is:public',
          shouldNavigate: true,
        },
        {
          name: 'Private',
          query: 'is:private',
          shouldNavigate: true,
        },
      ]
    case 'discussions':
      return [
        {
          name: 'Organization',
          query: 'org:',
        },
        {
          name: 'Author',
          query: 'author:',
        },
        {
          name: 'Involves user',
          query: 'involves:',
        },
        {
          name: 'Commenter',
          query: 'commenter:',
        },
        {
          name: 'Number of comments',
          query: 'comments:',
        },
        {
          name: 'Public',
          query: 'is:public',
          shouldNavigate: true,
        },
        {
          name: 'Private',
          query: 'is:private',
          shouldNavigate: true,
        },
      ]
    case 'registrypackages':
      return [
        {
          name: 'Organization',
          query: 'org:',
        },
        {
          name: 'User',
          query: 'user:',
        },
        {
          name: 'Public',
          query: 'is:public',
          shouldNavigate: true,
        },
        {
          name: 'Private',
          query: 'is:private',
          shouldNavigate: true,
        },
      ]
    case 'users':
      return [
        {
          name: 'Organization',
          query: 'org:',
        },
        {
          name: 'Full name',
          query: 'fullname:',
        },
        {
          name: 'Number of repositories',
          query: 'repos:',
        },
        {
          name: 'Number of followers',
          query: 'followers:',
        },
        {
          name: 'Location',
          query: 'location:',
        },
        {
          name: 'Language',
          query: 'language:',
        },
        {
          name: 'Creation date',
          query: 'created:',
        },
        {
          name: 'Sponsorable',
          query: 'is:sponsorable',
          shouldNavigate: true,
        },
      ]
    case 'topics':
      return [
        {
          name: 'Curated topics',
          query: 'is:curated',
          shouldNavigate: true,
        },
        {
          name: 'Featured topics',
          query: 'is:featured',
          shouldNavigate: true,
        },
        {
          name: 'Number of repositories',
          query: 'repositories:',
        },
        {
          name: 'Creation date',
          query: 'created:',
        },
      ]
    case 'wikis':
      return [
        {
          name: 'User',
          query: 'user:',
        },
        {
          name: 'Organization',
          query: 'org:',
        },
        {
          name: 'Repository',
          query: 'repo:',
        },
        {
          name: 'Last updated date',
          query: 'updated:',
        },
      ]
  }

  return []
}
