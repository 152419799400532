import {ActionList, ActionMenu, Button, Box, CounterLabel} from '@primer/react'
import TypeGroup from './TypeGroup'
import {ChevronDownIcon} from '@primer/octicons-react'
import {useLoggedInContext} from '../../contexts/LoggedInContext'

import type {SearchKindCount} from '../../hooks/use-search-result-counts'
import {capitalizeFirstLetter} from '../../utilities/format-type-title'
import {CountMode} from '../../../react-shared/Count'

import LazyCounter from '../LazyCounter'

export default function TypeMobileDropdown({
  selectedType,
  searchKinds,
  isLoadingCounters,
}: {
  selectedType: string | undefined
  isLoadingCounters: boolean
  searchKinds: SearchKindCount[]
}) {
  const searchKindCount = searchKinds?.find(kind => kind.name === selectedType) || searchKinds[0]!
  const loggedIn = useLoggedInContext()

  return (
    <ActionMenu>
      <ActionMenu.Anchor>
        <Button
          size="small"
          trailingVisual={ChevronDownIcon}
          data-testid="type-switcher"
          aria-label="Filter by type"
          sx={{mr: 2, span: {m: 0}}}
        >
          {capitalizeFirstLetter(searchKindCount.readableNamePlural)}
          <Box as="span" sx={{pl: 1}}>
            {selectedType === 'code' && !loggedIn ? (
              <CounterLabel data-testid="resolved-count-label" sx={{height: 20, lineHeight: '20px', py: 0, px: 2}}>
                ...
              </CounterLabel>
            ) : (
              <LazyCounter
                resultCountIsLowerBound={searchKindCount.mode === CountMode.LowerBound}
                totalResults={searchKindCount.total || 0}
                isLoading={searchKindCount.total === undefined}
              />
            )}
          </Box>
        </Button>
      </ActionMenu.Anchor>
      <ActionMenu.Overlay width="small">
        <ActionList selectionVariant="single" data-testid="type-switcher-options">
          <TypeGroup
            isLoadingCounters={isLoadingCounters}
            selectedType={selectedType}
            searchKinds={searchKinds}
            isMobile
          />
        </ActionList>
      </ActionMenu.Overlay>
    </ActionMenu>
  )
}

try{ TypeMobileDropdown.displayName ||= 'TypeMobileDropdown' } catch {}