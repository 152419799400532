import {useCallback, useMemo} from 'react'
import {getRelativeHref, type PathFunction, type PathParams, type Query} from '@github-ui/paths'
import {useSoftNavigate} from './use-soft-navigate'

/**
 * A link that performs soft navigation should perform the correct action on click,
 * and it should also show the correct href. This hook provides both.
 *
 * @example
 * ```tsx
 * function MyLink() {
 *   const [href, navigateToPath] = useSoftNavigateTo(myPath, {firstParam: 'asdf', secondParam: 'sdfg'})
 *
 *   return (
 *     <Link href={href} onClick={navigateToPath}>
 *       This is my super cool link!
 *     </Link>
 *   )
 * }
 * ```
 */
export function useSoftNavigateTo<T extends PathParams>(
  path: PathFunction<T>,
  params: T,
  query?: Query,
): [string, (e?: React.SyntheticEvent) => void] {
  const navigateTo = useSoftNavigate(path)
  const href = useMemo(() => getRelativeHref(path, params, query), [path, params, query])
  const navigateToPath = useCallback(
    (e?: React.SyntheticEvent) => {
      navigateTo(params, query)
      e?.preventDefault()
    },
    [params, query, navigateTo],
  )

  return [href, navigateToPath]
}
