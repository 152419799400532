import {IconButton, type IconButtonProps} from '@primer/react'
import {Tooltip} from '@primer/react/deprecated'
import React from 'react'
import {XIcon} from '@primer/octicons-react'

interface FacetNegateButtonProps extends Pick<IconButtonProps, 'onClick'> {
  ariaLabel: string
}

export default React.forwardRef(FacetNegateButtonWithRef)

function FacetNegateButtonWithRef(
  {ariaLabel, onClick}: FacetNegateButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
): JSX.Element | null {
  return (
    <Tooltip aria-label="Exclude from results" direction="nw">
      {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
      <IconButton
        unsafeDisableTooltip
        sx={{
          color: 'fg.muted',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          height: 32,
          width: 32,
        }}
        aria-label={ariaLabel}
        icon={XIcon}
        onClick={onClick}
        ref={ref}
        size="small"
        tabIndex={-1}
        variant="invisible"
      />
    </Tooltip>
  )
}

try{ FacetNegateButtonWithRef.displayName ||= 'FacetNegateButtonWithRef' } catch {}