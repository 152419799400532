import {Box} from '@primer/react'
import Base from './Base'

export default function CodeItemsSkeleton() {
  return (
    <Box data-testid="code-items-skeleton" sx={{pt: 0}}>
      <Base
        sx={{
          mb: 4,
          width: 190,
          borderRadius: 2,
          height: 10,
        }}
      />
      <Box sx={{display: 'grid', gap: 4}}>
        <CodeItem widths={[431, 309]} />
        <CodeItem widths={[475, 457]} />
        <CodeItem widths={[423, 480]} />
      </Box>
    </Box>
  )
}

function CodeItem({widths}: {widths: [number, number]}) {
  return (
    <div>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Base width={16} borderRadius={3} height={16} />
        <Base borderRadius={3} height={12} maxWidth={widths[0]} ml={2} />
      </Box>
      <Base borderRadius={2} height={200} mt={3} />
      <Box sx={{display: 'flex', mt: 3}}>
        <Base height={16} borderRadius={3} maxWidth={widths[1]} />
      </Box>
    </div>
  )
}

try{ CodeItemsSkeleton.displayName ||= 'CodeItemsSkeleton' } catch {}
try{ CodeItem.displayName ||= 'CodeItem' } catch {}