import {Box} from '@primer/react'

export default function LanguageCircle({color}: {color: string | undefined}) {
  return (
    <Box
      sx={{
        bg: color || 'attention.emphasis',
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'var(--borderColor-translucent)',
        width: 10,
        height: 10,
      }}
    />
  )
}

try{ LanguageCircle.displayName ||= 'LanguageCircle' } catch {}