import useColorModes from '@github-ui/react-core/use-color-modes'
import {useMemo} from 'react'

export default function MonaSearchImageNew() {
  const colorMode = useColorModes()

  const lightIllustrationPath = '/images/modules/search/light.png'
  const lightIllustration2xPath = '/images/modules/search/light2x.png'

  const darkIllustrationPath = '/images/modules/search/dark.png'
  const darkIllustration2xPath = '/images/modules/search/dark2x.png'

  const colorModeAwareMona = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustrationPath
      case 'day':
      default:
        return lightIllustrationPath
    }
  }, [colorMode])

  const colorModeAwareMona2x = useMemo(() => {
    switch (colorMode.colorMode) {
      case 'night':
        return darkIllustration2xPath
      case 'day':
      default:
        return lightIllustration2xPath
    }
  }, [colorMode])

  return (
    <img
      alt="Mona looking through a globe hologram for code"
      src={colorModeAwareMona}
      srcSet={`${colorModeAwareMona} 1x, ${colorModeAwareMona2x} 2x`}
      width="100%"
      style={{display: 'block'}}
    />
  )
}

try{ MonaSearchImageNew.displayName ||= 'MonaSearchImageNew' } catch {}