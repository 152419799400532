import type {PropsWithChildren} from 'react'
import {Box, type BoxProps} from '@primer/react'

export default function List({children, sx, ...boxProps}: PropsWithChildren<BoxProps>) {
  return (
    <Box
      {...boxProps}
      data-testid="results-list"
      sx={{display: 'grid', gap: 3, minWidth: 0, gridTemplateColumns: '1fr', ...sx}}
    >
      {children}
    </Box>
  )
}

try{ List.displayName ||= 'List' } catch {}