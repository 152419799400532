import {ActionList} from '@primer/react'

import {LinkExternalIcon} from '@primer/octicons-react'

export default function EducationGroup() {
  return (
    <ActionList.LinkItem
      href="https://github.com/search/advanced"
      sx={{
        span: {
          color: 'accent.fg',
        },
      }}
    >
      <ActionList.LeadingVisual>
        <LinkExternalIcon />
      </ActionList.LeadingVisual>
      Advanced search
    </ActionList.LinkItem>
  )
}

try{ EducationGroup.displayName ||= 'EducationGroup' } catch {}