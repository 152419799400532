import {useEffect} from 'react'

let lastSelectionTime = 0

// The purpose of this effect is to track when the user changes their selection,
// e.g. by highlighting or dismissing a highlight on a page. This is used to avoid
// triggering navigation to a search result if the user is trying to copy part
// of the snippet.
export function useTrackSelection() {
  let lastSelectionLength = 0

  useEffect(() => {
    const handler = () => {
      const newLength = window.getSelection()?.toString().length || 0
      if (newLength !== lastSelectionLength) {
        // There's no need to track this variable or trigger rerenders with it, since
        // it just stores the time of the last selection change.
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/exhaustive-deps
        lastSelectionLength = newLength
        lastSelectionTime = Date.now()
      }
    }
    document.addEventListener('selectionchange', handler)
    return () => {
      document.removeEventListener('selectionchange', handler)
    }
  })
}

export function timeSinceLastSelection() {
  return Date.now() - lastSelectionTime
}
