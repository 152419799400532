export function packageIconFromName(name: string, idSuffix?: string) {
  switch (name) {
    case 'docker':
      return <DockerIcon />
    case 'maven':
      return <MavenIcon idSuffix={idSuffix} />
    case 'rubygems':
      return <RubyGemsIcon />
    case 'nuget':
      return <NuGetIcon />
    case 'npm':
      return <NPMIcon />
    case 'actions':
      return <ActionIcon />
    case 'container':
      return <ContainerIcon />
    default:
      return <PackageIcon />
  }
}

function DockerIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="#2497ED" rx="4" />
      <path
        fill="#fff"
        d="M14.627 6.401c-.034-.027-.35-.265-1.018-.265-.176 0-.352.015-.525.045-.13-.886-.861-1.317-.894-1.337l-.18-.103-.117.17c-.148.229-.256.48-.32.745-.119.506-.046.98.21 1.387-.31.172-.807.215-.907.218H2.64a.391.391 0 00-.39.39c-.01.72.112 1.438.36 2.115.284.744.706 1.292 1.255 1.627.615.377 1.615.592 2.747.592a8.217 8.217 0 001.526-.138 6.398 6.398 0 001.991-.723 5.47 5.47 0 001.359-1.113c.652-.738 1.041-1.56 1.33-2.292h.115c.714 0 1.154-.285 1.395-.525.162-.153.287-.339.368-.545l.053-.15-.123-.098z"
      />
      <path
        fill="#fff"
        d="M3.405 7.004H4.52a.096.096 0 00.097-.096v-.982a.095.095 0 00-.06-.09.098.098 0 00-.037-.007H3.405a.096.096 0 00-.097.096v.984c0 .052.044.095.097.095zm1.536 0h1.115a.096.096 0 00.097-.096v-.982a.095.095 0 00-.06-.09.097.097 0 00-.037-.007H4.941a.098.098 0 00-.09.06.096.096 0 00-.008.037v.983c.001.052.045.095.098.095zm1.56 0h1.114a.096.096 0 00.097-.096v-.982a.095.095 0 00-.06-.09.098.098 0 00-.036-.007H6.5c-.026 0-.05.01-.07.028a.096.096 0 00-.028.068v.984c0 .052.044.095.098.095zm1.54 0h1.114a.096.096 0 00.098-.096v-.982a.096.096 0 00-.06-.09.098.098 0 00-.038-.007H8.041a.096.096 0 00-.097.096v.984c0 .052.043.095.097.095zm-3.1-1.413h1.115a.097.097 0 00.097-.097V4.51a.098.098 0 00-.097-.096H4.94a.096.096 0 00-.098.096v.983c.001.053.045.097.098.097zm1.56 0h1.114a.097.097 0 00.097-.097V4.51c0-.025-.01-.05-.028-.068a.098.098 0 00-.069-.028H6.501c-.026 0-.05.01-.07.028a.096.096 0 00-.028.068v.983c0 .053.044.097.098.097zm1.54 0h1.114a.098.098 0 00.098-.097V4.51a.098.098 0 00-.098-.096H8.041a.096.096 0 00-.097.096v.983c0 .053.043.097.097.097zm0-1.415h1.114a.096.096 0 00.098-.096v-.984A.098.098 0 009.155 3H8.041a.096.096 0 00-.097.096v.983c0 .054.043.097.097.097zm1.555 2.828h1.114c.026 0 .05-.01.07-.028a.096.096 0 00.028-.068v-.982a.097.097 0 00-.098-.097H9.596a.096.096 0 00-.097.096v.984a.095.095 0 00.06.088.097.097 0 00.037.007"
      />
    </svg>
  )
}

function MavenIcon({idSuffix}: {idSuffix?: string}) {
  idSuffix = idSuffix ?? ''
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g clipPath={`url(#clip0${idSuffix})`}>
        <path
          fill={`url(#paint0_linear${idSuffix})`}
          d="M2.896.87c-.101.238-.18.755-.217 1.447l.778.377a9.772 9.772 0 01.056-1.385l.006-.05c-.001.017-.005.033-.006.05-.016.14-.049.577-.004 1.366.322-.262.786-.707 1.152-1.088-.387-.79-.869-.929-.869-.929S3.103.38 2.896.87z"
        />
        <path fill="#BE202E" d="M5.592 6.844z" />
        <path fill="#BE202E" d="M5.592 6.844z" opacity="0.35" />
        <path
          fill="#BE202E"
          d="M5.307 6.224c-.002.002-.002.002 0 0 .007-.005.011-.012.018-.016.024-.025.049-.05.07-.075-.027.03-.058.06-.088.09z"
        />
        <path
          fill="#BE202E"
          d="M5.307 6.224c-.002.002-.002.002 0 0 .007-.005.011-.012.018-.016.024-.025.049-.05.07-.075-.027.03-.058.06-.088.09z"
          opacity="0.35"
        />
        <path
          fill={`url(#paint1_linear${idSuffix})`}
          d="M3.66 4.99a38.6 38.6 0 01-.098-.764 17.466 17.466 0 01-.108-1.533l-.777-.374c-.001.047-.005.096-.007.146-.008.186-.014.384-.015.592-.002.234 0 .478.004.734a26.533 26.533 0 00.072 1.38l.002.025 1.039.526c-.036-.246-.075-.49-.111-.732z"
        />
        <path
          fill={`url(#paint2_linear${idSuffix})`}
          d="M5.195 11.132c.057.194.116.394.179.596.002.002.001.006.003.008.008.03.015.057.026.086.042.136.082.258.167.536.168-.035.378.04.597.142a.852.852 0 00-.567-.308c.636-.424 1.054-.97.995-1.622a2.239 2.239 0 00-.025-.178c-.01.25-.114.43-.418.628l-.002.001.002-.001c.287-.506.367-.85.333-1.294a4.238 4.238 0 00-.041-.337c-.092.661-.447 1.089-.966 1.342l-.315.29c.011.038.02.073.032.11z"
        />
        <path
          fill={`url(#paint3_linear${idSuffix})`}
          d="M4.79 10.245l-.198-.749a48.91 48.91 0 01-.361-1.479c-.06-.254-.115-.509-.169-.765-.054-.252-.103-.507-.153-.759a32.065 32.065 0 01-.133-.745c-.003-.007-.004-.017-.006-.025l-1.033-.527c.001.016.004.033.004.05.02.234.04.471.068.71a21.4 21.4 0 00.192 1.35l.022.125a14.896 14.896 0 00.483 1.908c.039.116.082.232.123.346.098.269.208.53.326.784l1.043.529c-.055-.192-.107-.387-.163-.585l-.045-.168z"
        />
        <path
          fill={`url(#paint4_linear${idSuffix})`}
          d="M3.97 10.503a8.1 8.1 0 00.48.887c.007.008.012.02.019.03-.289-.117-.8-.063-.796-.062.527.183.99.407 1.282.746-.116.128-.333.225-.606.305.377.04.588-.026.665-.06-.197.165-.308.466-.405.79.222-.363.443-.604.657-.696.313 1.04.69 2.165 1.109 3.347a.314.314 0 00.049-.295 71.552 71.552 0 01-1.142-3.526c-.016-.053-.03-.108-.048-.164l-.014-.046c-.058-.2-.118-.405-.178-.617l-.04-.144L5 10.996l-1.043-.529a.195.195 0 00.013.036z"
        />
        <path
          fill={`url(#paint5_linear${idSuffix})`}
          d="M3.893 5.744l.019.101c.02.104.038.208.06.314.021.115.045.232.068.353l.036.183.12.57c.052.24.11.488.17.745.057.243.118.496.183.754.062.246.126.498.196.758.06.23.126.467.193.71.002.012.008.024.01.036a92.063 92.063 0 00.215.753l.315-.29c-.008.002-.014.007-.025.011.374-.353.694-1.06.768-1.697.033-.294.03-.606-.01-.947a6.5 6.5 0 00-.152-.81c-.062-.25-.138-.514-.232-.793-.058.118-.14.233-.24.347l-.055.06c-.018.02-.038.037-.058.058.276-.428.308-.888.227-1.366a1.73 1.73 0 01-.312.54c-.02.026-.045.05-.07.075-.006.004-.011.011-.02.018a1.918 1.918 0 00.226-.537 1.9 1.9 0 00.026-.143c.003-.032.008-.061.01-.093a1.34 1.34 0 000-.225c-.003-.025-.003-.048-.007-.071-.006-.035-.01-.068-.017-.1a4.719 4.719 0 00-.122-.532c-.007-.018-.01-.036-.017-.054-.017-.05-.03-.095-.045-.134a1.34 1.34 0 00-.058-.131.29.29 0 01-.022.062 2.043 2.043 0 01-.355.51l.29-.266-.29.265c-.002.002-.004.004-.004.007-.014.013-.03.028-.043.045 0-.004.005-.007.005-.01l-.992.908c.009.007.01.012.009.016z"
        />
        <path
          fill={`url(#paint6_linear${idSuffix})`}
          d="M3.51 2.675c.011.218.03.463.055.733.002.015.001.029.005.042a28.913 28.913 0 00.198 1.533c.035.237.079.484.124.746l.992-.909c.206-.374.252-.607.249-.897a7.805 7.805 0 00-.101-1.027 6.791 6.791 0 00-.148-.702 3.721 3.721 0 00-.114-.355 4.047 4.047 0 00-.11-.248c-.365.377-.829.822-1.15 1.084z"
        />
        <path fill="#BE202E" d="M5.54 6.902c-.018.02-.038.038-.059.06.019-.02.037-.04.059-.06z" />
        <path fill="#BE202E" d="M5.54 6.902c-.018.02-.038.038-.059.06.019-.02.037-.04.059-.06z" opacity="0.35" />
        <path
          fill={`url(#paint7_linear${idSuffix})`}
          d="M5.54 6.902c-.018.02-.038.038-.059.06.019-.02.037-.04.059-.06z"
        />
        <path fill="#BE202E" d="M5.305 6.226c.007-.005.011-.012.02-.018-.007.004-.011.011-.02.018z" />
        <path fill="#BE202E" d="M5.305 6.226c.007-.005.011-.012.02-.018-.007.004-.011.011-.02.018z" opacity="0.35" />
        <path
          fill={`url(#paint8_linear${idSuffix})`}
          d="M5.305 6.226c.007-.005.011-.012.02-.018-.007.004-.011.011-.02.018z"
        />
        <path
          fill={`url(#paint9_linear${idSuffix})`}
          d="M13.06 1.065c-.27.112-.745.465-1.324.993l.327.921c.384-.409.769-.77 1.145-1.07l.045-.036c-.014.013-.03.023-.045.036-.123.1-.497.425-1.088 1.097.468.044 1.194.054 1.792.04.317-.945.039-1.44.039-1.44s-.334-.77-.89-.54z"
        />
        <path
          fill="#BE202E"
          d="M10.484 8.017c-.06.003-.121.003-.181.003.03.002.063 0 .093-.001.03.001.06 0 .088-.002z"
        />
        <path
          fill="#BE202E"
          d="M10.484 8.017c-.06.003-.121.003-.181.003.03.002.063 0 .093-.001.03.001.06 0 .088-.002z"
          opacity="0.35"
        />
        <path
          fill="#BE202E"
          d="M10.748 7.29c-.003 0-.003 0 0 0 .009.002.018 0 .027.002.04 0 .079 0 .115-.004-.045.002-.093.001-.142.003z"
        />
        <path
          fill="#BE202E"
          d="M10.748 7.29c-.003 0-.003 0 0 0 .009.002.018 0 .027.002.04 0 .079 0 .115-.004-.045.002-.093.001-.142.003z"
          opacity="0.35"
        />
        <path
          fill={`url(#paint10_linear${idSuffix})`}
          d="M10.403 4.99c.177-.237.352-.468.527-.693.183-.234.367-.457.553-.675a17.928 17.928 0 01.578-.646l-.328-.918-.122.112c-.154.143-.316.298-.482.464-.187.187-.38.385-.58.595-.182.193-.37.398-.557.61-.16.182-.32.367-.481.557l-.018.022.418 1.249.492-.677z"
        />
        <path
          fill={`url(#paint11_linear${idSuffix})`}
          d="M6.758 11.153c-.108.201-.22.409-.33.622 0 .003-.003.005-.004.008-.017.031-.033.06-.046.09-.076.143-.14.273-.292.565.163.105.272.333.367.588a.963.963 0 00-.211-.698c.849.164 1.62.056 2.09-.515.041-.052.082-.106.12-.164-.206.193-.433.257-.834.174h-.003.003c.633-.18.97-.393 1.295-.777.077-.091.155-.192.235-.304-.6.459-1.225.521-1.844.313l-.484-.017c-.02.04-.04.075-.062.115z"
        />
        <path
          fill={`url(#paint12_linear${idSuffix})`}
          d="M7.137 10.118c.142-.251.288-.505.435-.761a55.32 55.32 0 01.884-1.478c.155-.252.312-.5.473-.75.157-.245.32-.49.479-.733a36.279 36.279 0 01.484-.705c.004-.008.012-.016.016-.025l-.413-1.245c-.01.014-.022.03-.036.043-.17.205-.342.412-.51.627a24.167 24.167 0 00-.918 1.24l-.08.117a16.827 16.827 0 00-1.128 1.92 11.757 11.757 0 00-.536 1.266l.42 1.254c.107-.198.22-.396.332-.6l.098-.17z"
        />
        <path
          fill={`url(#paint13_linear${idSuffix})`}
          d="M6.271 9.673a9.162 9.162 0 00-.317 1.096l-.009.038c-.14-.323-.594-.686-.592-.682.28.566.474 1.115.44 1.62-.196.01-.448-.084-.73-.237.271.333.493.447.582.481-.29-.024-.618.13-.953.314.467-.116.836-.135 1.081-.038a85.804 85.804 0 00-1.766 3.575.355.355 0 00.274-.199 80.9 80.9 0 011.882-3.745c.03-.056.062-.112.091-.17l.025-.048c.112-.208.228-.42.348-.638.028-.05.053-.098.082-.148v-.003l-.42-1.255a.22.22 0 00-.018.04z"
        />
        <path
          fill={`url(#paint14_linear${idSuffix})`}
          d="M9.99 5.781l-.065.096c-.066.1-.135.198-.2.3l-.226.339c-.039.057-.077.115-.116.176-.117.179-.235.364-.357.555a61.946 61.946 0 00-.907 1.486c-.145.248-.294.5-.444.766-.134.234-.27.477-.409.725l-.02.037c-.136.245-.276.5-.417.76-.004.006-.008.011-.009.017l.484.017c-.008-.005-.017-.006-.028-.01.581.012 1.4-.303 1.966-.758.26-.21.506-.463.744-.77.179-.227.35-.483.521-.772.149-.25.298-.524.444-.823a1.782 1.782 0 01-.56.093c-.03.002-.06 0-.094.001.562-.126.954-.47 1.268-.92a1.957 1.957 0 01-.68.188c-.037.003-.076.004-.115.004l-.03-.001c.194-.05.364-.113.515-.197.032-.016.062-.036.092-.056.047-.029.091-.062.135-.095l.083-.066a1.52 1.52 0 00.177-.182c.019-.022.036-.04.052-.062.023-.033.045-.063.065-.094a5.321 5.321 0 00.325-.526c.009-.02.02-.036.03-.056.025-.054.05-.101.069-.144.027-.062.047-.114.058-.151a.328.328 0 01-.068.032 2.31 2.31 0 01-.69.128l.445.017-.445-.017c-.003 0-.005 0-.009.002-.021 0-.045 0-.07.002.004-.003.01-.002.013-.004l-1.52-.057c0 .012-.003.017-.006.02z"
        />
        <path
          fill={`url(#paint15_linear${idSuffix})`}
          d="M12.12 3.006c-.163.184-.342.397-.537.634-.011.013-.022.024-.03.038a32.48 32.48 0 00-1.06 1.391c-.158.219-.32.452-.491.699l1.52.056c.464-.137.686-.289.914-.524a8.814 8.814 0 00.734-.907c.168-.236.32-.47.439-.682.077-.137.141-.264.19-.377.043-.1.078-.194.11-.286-.595.012-1.321.002-1.79-.042z"
        />
        <path fill="#BE202E" d="M10.396 8.022l-.094.001a.856.856 0 01.094-.001z" />
        <path fill="#BE202E" d="M10.396 8.022l-.094.001a.856.856 0 01.094-.001z" opacity="0.35" />
        <path fill={`url(#paint16_linear${idSuffix})`} d="M10.396 8.022l-.094.001a.856.856 0 01.094-.001z" />
        <path fill="#BE202E" d="M10.745 7.29c.009.002.018 0 .03.002-.009-.001-.018 0-.03-.002z" />
        <path fill="#BE202E" d="M10.745 7.29c.009.002.018 0 .03.002-.009-.001-.018 0-.03-.002z" opacity="0.35" />
        <path
          fill={`url(#paint17_linear${idSuffix})`}
          d="M10.745 7.29c.009.002.018 0 .03.002-.009-.001-.018 0-.03-.002z"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear${idSuffix}`}
          x1="3.012"
          x2="4.914"
          y1="2.188"
          y2="-0.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69923" />
          <stop offset="0.312" stopColor="#F79A23" />
          <stop offset="0.838" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint1_linear${idSuffix}`}
          x1="5.652"
          x2="2.863"
          y1="16.748"
          y2="2.761"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint2_linear${idSuffix}`}
          x1="6.719"
          x2="5.057"
          y1="14.78"
          y2="6.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset="0.095" stopColor="#662E8D" />
          <stop offset="0.788" stopColor="#9F2064" />
          <stop offset="0.949" stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id={`paint3_linear${idSuffix}`}
          x1="5.365"
          x2="2.576"
          y1="15.988"
          y2="2.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint4_linear${idSuffix}`}
          x1="5.351"
          x2="3.885"
          y1="14.93"
          y2="7.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset="0.095" stopColor="#662E8D" />
          <stop offset="0.788" stopColor="#9F2064" />
          <stop offset="0.949" stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id={`paint5_linear${idSuffix}`}
          x1="6.813"
          x2="4.024"
          y1="15.7"
          y2="1.712"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint6_linear${idSuffix}`}
          x1="6.914"
          x2="4.125"
          y1="16.581"
          y2="2.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint7_linear${idSuffix}`}
          x1="7.244"
          x2="4.454"
          y1="15.614"
          y2="1.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint8_linear${idSuffix}`}
          x1="7.194"
          x2="4.404"
          y1="15.623"
          y2="1.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint9_linear${idSuffix}`}
          x1="12.107"
          x2="15.559"
          y1="2.218"
          y2="1.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69923" />
          <stop offset="0.312" stopColor="#F79A23" />
          <stop offset="0.838" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint10_linear${idSuffix}`}
          x1="2.664"
          x2="11.532"
          y1="16.037"
          y2="2.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint11_linear${idSuffix}`}
          x1="5.087"
          x2="10.371"
          y1="15.3"
          y2="7.269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset="0.095" stopColor="#662E8D" />
          <stop offset="0.788" stopColor="#9F2064" />
          <stop offset="0.949" stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id={`paint12_linear${idSuffix}`}
          x1="3.037"
          x2="11.905"
          y1="15.197"
          y2="1.722"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint13_linear${idSuffix}`}
          x1="3.866"
          x2="8.526"
          y1="14.335"
          y2="7.253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset="0.095" stopColor="#662E8D" />
          <stop offset="0.788" stopColor="#9F2064" />
          <stop offset="0.949" stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id={`paint14_linear${idSuffix}`}
          x1="4.432"
          x2="13.299"
          y1="16.115"
          y2="2.639"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint15_linear${idSuffix}`}
          x1="3.812"
          x2="12.68"
          y1="16.905"
          y2="3.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint16_linear${idSuffix}`}
          x1="4.846"
          x2="13.714"
          y1="16.388"
          y2="2.912"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id={`paint17_linear${idSuffix}`}
          x1="4.799"
          x2="13.666"
          y1="16.356"
          y2="2.881"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.323" stopColor="#9E2064" />
          <stop offset="0.63" stopColor="#C92037" />
          <stop offset="0.751" stopColor="#CD2335" />
          <stop offset="1" stopColor="#E97826" />
        </linearGradient>
        <clipPath id={`clip0${idSuffix}`}>
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function NPMIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="#CB0000" rx="4" />
      <path fill="#fff" d="M7.96 3.289H3.288v9.422h4.67V5.665h2.376v7.046h2.376V3.289H7.959z" />
    </svg>
  )
}

function NuGetIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#004880"
        fillRule="evenodd"
        d="M11.663 14.612a2.737 2.737 0 110-5.474 2.737 2.737 0 010 5.474zM6.203 8.34a1.71 1.71 0 110-3.422 1.71 1.71 0 010 3.422zM11.783 3H7.217A4.217 4.217 0 003 7.217v4.567A4.216 4.216 0 007.216 16h4.568A4.215 4.215 0 0016 11.784V7.217A4.216 4.216 0 0011.784 3z"
        clipRule="evenodd"
      />
      <mask
        id="mask0_4204_241687"
        style={{maskType: 'alpha'}}
        width="3"
        height="3"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M0 1.371V.001h2.741v2.74H0v-1.37z" />
      </mask>
      <g mask="url(#mask0_4204_241687)">
        <path
          fill="#004880"
          fillRule="evenodd"
          d="M2.741 1.37A1.37 1.37 0 110 1.37a1.37 1.37 0 012.741 0z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}

function PackageIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#0969DA"
        fillRule="evenodd"
        d="M10.41.24l4.711 2.774A1.767 1.767 0 0116 4.54v5.01c0 .63-.335 1.213-.88 1.53l-7.753 4.521-.002.001a1.767 1.767 0 01-1.774 0H5.59L.873 12.85A1.762 1.762 0 010 11.327V6.292c0-.304.078-.598.22-.855l.004-.005.01-.019c.15-.262.369-.486.64-.643L8.641.239a1.75 1.75 0 011.765 0l.002.001zM9.397 1.534a.25.25 0 01.252 0l4.115 2.422-7.152 4.148a.267.267 0 01-.269 0L2.227 5.716l7.17-4.182zM7.365 9.402L8.73 8.61v4.46l-1.5.875V9.473a1.77 1.77 0 00.136-.071zm2.864 2.794V7.741l1.521-.882v4.45l-1.521.887zm3.021-1.762l1.115-.65h.002a.268.268 0 00.133-.232V5.264l-1.25.725v4.445zm-11.621 1.12l4.1 2.393V9.474a1.77 1.77 0 01-.138-.072L1.5 7.029v4.298c0 .095.05.181.129.227z"
        clipRule="evenodd"
      />
    </svg>
  )
}

function RubyGemsIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g fill="#D34231" clipPath="url(#clip0_4204_241713)">
        <path d="M5.2 5.285l-.01-.009L3.212 7.26l4.806 4.797 1.978-1.974 2.827-2.823-1.978-1.983v-.008H5.19l.01.017z" />
        <path d="M8 0L1 4.014v8.025l7 4.01 7-4.014v-8.02L8 0zm5.663 11.27L8 14.532 2.337 11.27V4.762L8 1.499l5.663 3.263v6.508z" />
      </g>
      <defs>
        <clipPath id="clip0_4204_241713">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function ActionIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
      <path
        fillRule="evenodd"
        d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zM6.379 5.227A.25.25 0 006 5.442v5.117a.25.25 0 00.379.214l4.264-2.559a.25.25 0 000-.428L6.379 5.227z"
      />
    </svg>
  )
}

function ContainerIcon() {
  return (
    // eslint-disable-next-line github/a11y-svg-has-accessible-name
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
      <path
        fillRule="evenodd"
        d="M10.41.24l4.711 2.774A1.767 1.767 0 0116 4.54v5.01a1.77 1.77 0 01-.88 1.53l-7.753 4.521-.002.001a1.767 1.767 0 01-1.774 0H5.59L.873 12.85A1.762 1.762 0 010 11.327V6.292c0-.304.078-.598.22-.855l.004-.005.01-.019c.15-.262.369-.486.64-.643L8.641.239a1.75 1.75 0 011.765 0l.002.001zM9.397 1.534a.25.25 0 01.252 0l4.115 2.422-7.152 4.148a.267.267 0 01-.269 0L2.227 5.716l7.17-4.182zM7.365 9.402L8.73 8.61v4.46l-1.5.875V9.473a1.77 1.77 0 00.136-.071zm2.864 2.794V7.741l1.521-.882v4.45l-1.521.887zm3.021-1.762l1.115-.65h.002a.268.268 0 00.133-.232V5.264l-1.25.725v4.445zm-11.621 1.12l4.1 2.393V9.474a1.77 1.77 0 01-.138-.072L1.5 7.029v4.298c0 .095.05.181.129.227z"
      />
    </svg>
  )
}

try{ DockerIcon.displayName ||= 'DockerIcon' } catch {}
try{ MavenIcon.displayName ||= 'MavenIcon' } catch {}
try{ NPMIcon.displayName ||= 'NPMIcon' } catch {}
try{ NuGetIcon.displayName ||= 'NuGetIcon' } catch {}
try{ PackageIcon.displayName ||= 'PackageIcon' } catch {}
try{ RubyGemsIcon.displayName ||= 'RubyGemsIcon' } catch {}
try{ ActionIcon.displayName ||= 'ActionIcon' } catch {}
try{ ContainerIcon.displayName ||= 'ContainerIcon' } catch {}