import {Box} from '@primer/react'

import Header from './Header'
import Footer from './Footer'
import Tips from './Tips'
import type {SearchKind, SearchKindCount} from '../../hooks/use-search-result-counts'
import {generateTips} from './data'
import MonaSearchImage from './MonaSearchImage'

export default function NoResults({
  resultKind,
  searchKinds,
  isLoadingCounters,
}: {
  resultKind: SearchKind
  searchKinds: SearchKindCount[]
  isLoadingCounters: boolean
}) {
  return (
    <Box
      data-hpc
      sx={{
        width: '100%',
        flex: 1,
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr', 'auto 1fr'],
        gridGap: [2, 2, 4, 4, 6],
        pr: [0, 0, 6, 8, 12],
        pl: [0, 0, 6, 7, 7],
        borderStyle: 'solid',
        borderWidth: [0, 0, 1, 1],
        borderRadius: 2,
        borderColor: 'border.default',
        py: [6, 7, 8, 10, 12],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              maxWidth: [250, 300, 380, 400, 500],
              width: '100%',
              pb: [2, 2, 2, 2, 0],
            }}
          >
            <MonaSearchImage />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: ['100%', '100%', '100%', 600],
          minWidth: ['100%', '100%', '100%', 500],
          mx: ['auto', 'auto', 'auto', 'auto', 0],
        }}
      >
        <Header resultKind={resultKind} searchKinds={searchKinds} isLoadingCounters={isLoadingCounters} />
        <Tips data={generateTips({resultKind})} />
        <Box sx={{pt: 3, pb: [0, 0, 7]}}>
          <Footer resultKind={resultKind} />
        </Box>
      </Box>
    </Box>
  )
}

try{ NoResults.displayName ||= 'NoResults' } catch {}