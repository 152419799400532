import React, {type PropsWithChildren, isValidElement} from 'react'
import {Box} from '@primer/react'

/**
 * A styled wrapper for a row of tokens in a search result, such as the list of topics associated with a repo.
 */
export default function TokenList({children}: PropsWithChildren) {
  // Filter out falsy children to avoid wrapping `null` or `false` in a Box.
  // eslint-disable-next-line @eslint-react/no-children-to-array
  const childrenArray = React.Children.toArray(children).filter(child => !!child)
  return (
    <Box sx={{mb: 1, mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2}}>
      {childrenArray.map((child, i) => {
        const childKey = isValidElement(child) ? child.key : i
        return <div key={childKey}>{child}</div>
      })}
    </Box>
  )
}

try{ TokenList.displayName ||= 'TokenList' } catch {}