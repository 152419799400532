import type React from 'react'
import {useEffect, useMemo, useRef, useState} from 'react'
import type {SearchResponse, SearchResultsType} from '../types/blackbird-types'
import {getExpandedQuery, parseString} from '../../../../../app/components/search/parsing/parsing'
import {getCustomScopesFromParam} from './use-navigate-to-query'
import {CountMode} from '../../react-shared/Count'

export interface SearchKind {
  name: SearchResultsType
  readableName: string
  readableNamePlural: string
  icon: React.FunctionComponent
}
interface ResultCount {
  mode: CountMode
  total: number
  final: boolean
  error: boolean
}

export type SearchKindCount = SearchKind & ResultCount

const MaxBlackbirdResults = 100

export const useSearchResultCounts = ({
  searchType,
  kinds,
  query,
  scopes,
  payload,
}: {
  searchType: SearchResultsType
  kinds: SearchKind[]
  query: string | null
  scopes: string | null
  payload: SearchResponse | null
}): [boolean, SearchKindCount[], ResultCount] => {
  const [isLoading, setIsLoading] = useState(true)
  const [counts, setCounts] = useState(() => {
    const m = new Map<string, ResultCount>()
    for (const kind of kinds) {
      m.set(kind.name, {total: 0, mode: CountMode.Exact, error: false, final: false})
    }
    return m
  })
  const prevQuery = useRef('')
  const prevPayload = useRef<SearchResponse | null>(null)
  const prevSearchType = useRef(searchType)

  // set the initial value of counts to include the count we got from the search results
  useMemo(() => {
    if (
      !shouldRecount({
        prevSearchType,
        searchType,
        prevQuery,
        query,
        prevPayload,
        payload,
      })
    ) {
      return
    }

    if (!payload) {
      return
    }

    // If the blackbird query returned 100 results, follow up with a count request
    if (searchType === 'code' && payload.result_count === MaxBlackbirdResults) {
      // Set a lower bound estimate while we get the real count
      counts.set(searchType, {
        total: payload.result_count,
        mode: CountMode.LowerBound,
        final: false,
        error: false,
      })
    } else {
      counts.set(searchType, {
        total: payload.result_count,
        mode: CountMode.Exact,
        final: true,
        error: false,
      })
    }
  }, [counts, payload, query, searchType])

  // get search counts from the server
  useEffect(() => {
    if (
      !shouldRecount({
        prevSearchType,
        searchType,
        prevQuery,
        query,
        prevPayload,
        payload,
      })
    ) {
      return
    }

    if (!query) {
      setIsLoading(false)
      return
    }

    if (!payload) {
      setIsLoading(true)
      return
    }

    prevQuery.current = query
    prevPayload.current = payload
    prevSearchType.current = searchType
    setIsLoading(true)

    let expandedQuery = query
    if (scopes) {
      const customScopes = getCustomScopesFromParam(scopes)
      if (customScopes.length > 0) {
        const ast = parseString(query)
        expandedQuery = getExpandedQuery(query, customScopes, ast)
      }
    }

    ;(async () => {
      const kindsToFetch = kinds.filter(kind => kind.name !== searchType)

      // Begin fetching counts other than the type we have via payload
      const otherResults = fetchAllCounts({
        kinds: kindsToFetch,
        query,
        expandedQuery,
        scopes: scopes || '',
        loggedIn: !!payload.logged_in,
      })

      // If the blackbird query returned 100 results, follow up with a count request
      if (payload.logged_in && searchType === 'code' && payload.result_count === MaxBlackbirdResults) {
        const blackbirdCount = await fetchCount({
          type: 'code',
          query,
          expandedQuery,
          scopes: scopes || '',
          loggedIn: true,
        })
        if (!blackbirdCount.error) {
          counts.set(searchType, blackbirdCount)
        }
      }

      const results = await otherResults
      for (let i = 0; i < results.length; i++) {
        const result = results[i]!
        if (result.status === 'fulfilled') {
          counts.set(kindsToFetch[i]!.name, {
            total: result.value.total,
            mode: result.value.mode,
            final: true,
            error: false,
          })
        } else {
          counts.set(kindsToFetch[i]!.name, {
            total: 0,
            mode: CountMode.Exact,
            final: true,
            error: true,
          })
        }
      }

      setCounts(counts)
      setIsLoading(false)
    })()
    // I am only writing to counts, not reading from it. But typescript doesn't know that
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kinds, payload, scopes, query, prevPayload, prevQuery, searchType])

  const output = [] as SearchKindCount[]
  for (const kind of kinds) {
    const count = counts.get(kind.name)
    if (count) {
      output.push({...kind, ...count})
    }
  }
  return [isLoading, output, counts.get(searchType)!]
}

function shouldRecount({
  prevSearchType,
  searchType,
  prevQuery,
  query,
  prevPayload,
  payload,
}: {
  prevSearchType: React.MutableRefObject<SearchResultsType>
  searchType: SearchResultsType
  prevQuery: React.MutableRefObject<string>
  query: string | null
  prevPayload: React.MutableRefObject<SearchResponse | null>
  payload: SearchResponse | null
}) {
  // When switching from legacy code to new code search (or the reverse), we need to
  // recalculate the counts, even if the query remains the same.
  const isCodeToLegacyTransition =
    (prevSearchType.current === 'code' && searchType === 'codelegacy') ||
    (prevSearchType.current === 'codelegacy' && searchType === 'code')

  // Don't recalculate the counts if the query hasn't changed
  if (!isCodeToLegacyTransition && (prevQuery.current === query || prevPayload.current === payload)) {
    return false
  }
  return true
}

function fetchAllCounts({
  kinds,
  query,
  expandedQuery,
  scopes,
  loggedIn,
}: {
  kinds: SearchKind[]
  query: string
  expandedQuery: string
  scopes: string
  loggedIn: boolean
}) {
  const mapToPromises = kinds.map(kind => fetchCount({type: kind.name, query, expandedQuery, scopes, loggedIn}))
  return Promise.allSettled(mapToPromises)
}

async function fetchCount({
  type,
  query,
  expandedQuery,
  scopes,
  loggedIn,
}: {
  type: SearchResultsType
  query: string
  expandedQuery: string
  scopes: string
  loggedIn: boolean
}): Promise<ResultCount> {
  const typeValue = type === 'codelegacy' ? 'code' : type

  if (type === 'code') {
    if (!loggedIn) {
      return {total: 0, mode: CountMode.Exact, final: true, error: false}
    }
    const url = new URL('/search/blackbird_count', window.location.href)
    url.searchParams.append('saved_searches', scopes)
    url.searchParams.append('q', query)
    const response = await fetch(url.toString(), {
      headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
    })
    const json = await response.json()
    return {total: Number(json.count), mode: json.mode, final: true, error: json.failed}
  }

  if (type === 'issues') {
    expandedQuery += ' is:issue'
  } else if (type === 'pullrequests') {
    expandedQuery += ' is:pr'
  }

  const url = new URL('/search/count', window.location.href)
  url.searchParams.append('q', expandedQuery)
  url.searchParams.append('type', typeValue)

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`${response.status}`)
  }

  const result = await response.text()
  return extractContent(type, result)
}

function extractContent(name: SearchResultsType, html: string): ResultCount {
  const el = document.createElement('html')
  el.innerHTML = html
  let text = (el.textContent || '0').toLowerCase()
  let multiplier = 1
  let isLowerBound = false

  if (text.endsWith('+')) {
    isLowerBound = true
    text = text.slice(0, -1)
  }

  if (text.endsWith('k')) {
    multiplier = 1000
    text = text.slice(0, -1)
  } else if (text?.endsWith('m')) {
    multiplier = 1000000
    text = text.slice(0, -1)
  } else if (text?.endsWith('b')) {
    multiplier = 1000000000
    text = text.slice(0, -1)
  }

  return {
    mode: isLowerBound ? CountMode.LowerBound : CountMode.Exact,
    total: Number(text) * multiplier,
    final: true,
    error: false,
  }
}
