import type {SearchResults, SearchResultsType} from '../../types/blackbird-types'
import {Box} from '@primer/react'

import MiniTip from './MiniTip'
import MarketingSuggestion from './MarketingSuggestion'
import Feedback from './Feedback'
import {useLoggedInContext} from '../../contexts/LoggedInContext'
interface MarketingSuggestion {
  title: string
  description: string
  mediaPath: string
  mediaPathRetina?: string
  mediaAlt: string
  cta: string
  href: string
  show: SearchResultsType[]
}

const marketingSuggestions: MarketingSuggestion[] = [
  {
    title: 'Learn GitHub Packages',
    description:
      'GitHub Packages is a platform for hosting and managing packages, including containers and other dependencies. Get started with publishing or installing packages yourself.',
    mediaPath: '/images/modules/search/package.svg',
    mediaAlt: 'Package Icon',
    cta: 'Learn more about GitHub Packages',
    href: '/features/packages',
    show: ['registrypackages'],
  },
  {
    title: 'Sponsor open source projects you depend on',
    description:
      'Contributors are working behind the scenes to make open source better for everyone—give them the help and recognition they deserve.',
    mediaPath: '/images/modules/search/mona-love.png',
    mediaPathRetina: '/images/modules/search/mona-love-2x.png',
    mediaAlt: 'Package icon',
    cta: 'Explore sponsorable projects',
    href: 'https://github.com/sponsors/explore',
    show: ['repositories', 'users'],
  },
  {
    title: 'Learn how you can use GitHub Issues to plan and track your work.',
    description:
      'Save views for sprints, backlogs, teams, or releases. Rank, sort, and filter issues to suit the occasion. The possibilities are endless.',
    mediaPath: '/images/modules/search/issues.png',
    mediaPathRetina: '/images/modules/search/issues-2x.png',
    mediaAlt: 'Issue origami icon',
    cta: 'Learn more about GitHub Issues',
    href: 'https://docs.github.com/issues',
    show: ['issues'],
  },
]

export default function SecondarySuggestions({payload}: {payload: SearchResults}) {
  const type = payload.type
  const suggestion = marketingSuggestions.find(tip => tip.show.includes(type))
  const loggedIn = useLoggedInContext()

  if (!suggestion) {
    return null
  }

  return (
    <Box sx={{display: 'grid', gap: 3}}>
      {suggestion && (
        <MarketingSuggestion
          title={suggestion.title}
          description={suggestion.description}
          mediaPath={suggestion.mediaPath}
          mediaPathRetina={suggestion.mediaPathRetina}
          mediaAlt={suggestion.mediaAlt}
          cta={suggestion.cta}
          href={suggestion.href}
        />
      )}
      {loggedIn && <Feedback />}
      <MiniTip payload={payload} />
    </Box>
  )
}

try{ SecondarySuggestions.displayName ||= 'SecondarySuggestions' } catch {}