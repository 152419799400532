import {Ago} from '@github-ui/ago'
import {Label, Link} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {CommentDiscussionIcon} from '@primer/octicons-react'
import type {IssueResultItem, IssuesResults} from '../../types/blackbird-types'
import Result from '../search-result'
import {GitHubAvatar} from '@github-ui/github-avatar'

import {getIssueIcon, getIssueIconColor} from '../../utilities/issue-utils'

export default function Issues({results}: {results: IssuesResults}) {
  return (
    <Result.List>
      {results.results.map((item: IssueResultItem, i: number) => (
        <IssueResult
          // eslint-disable-next-line @eslint-react/no-array-index-key
          key={i}
          item={item}
          isPullRequest={false}
        />
      ))}
    </Result.List>
  )
}

export function IssueResult({item, isPullRequest}: {item: IssueResultItem; isPullRequest: boolean}) {
  const repositoryName = item.repo.repository.name
  const repositoryOwnerName = item.repo.repository.owner_login
  const repositoryUrl = `/${repositoryOwnerName}/${repositoryName}`
  const issueNumber = item.number
  const issueUrl = `${repositoryUrl}/${isPullRequest ? 'pull' : 'issues'}/${issueNumber}`
  const issueTitle = item.hl_title
  const issueDescription = item.hl_text
  const issueAuthor = item.author_name
  const issueAuthorAvatarUrl = item.development_avatar || item.author_avatar_url
  const issueIsPr = !!item.issue.issue.pull_request_id
  const createdDate = new Date(item.created)
  const numComments = item.num_comments

  const iconColor = getIssueIconColor(item.state, issueIsPr, item.merged, item.reviewable_state, item.state_reason)

  return (
    <Result>
      <Result.Header>
        <Result.RepositoryContext name={repositoryName} owner={repositoryOwnerName} />
        <Result.Title>
          <Octicon
            icon={getIssueIcon(item.state, issueIsPr, item.state_reason)}
            size={16}
            sx={{
              mr: 2,
              color: iconColor,
            }}
          />
          <Link href={issueUrl}>
            <Result.SearchMatchText text={issueTitle} />
          </Link>
        </Result.Title>
      </Result.Header>
      {issueDescription && (
        <Result.Content>
          <Result.SearchMatchText text={issueDescription} />
        </Result.Content>
      )}
      {item.labels.length > 0 && (
        <Result.TokenList>
          {item.labels.map((label, i) => (
            // eslint-disable-next-line @eslint-react/no-array-index-key
            <Label key={i}>
              <Result.SearchMatchText text={label} />
            </Label>
          ))}
        </Result.TokenList>
      )}
      <Result.Footer>
        <Result.FooterItem>
          <GitHubAvatar
            square
            size={16}
            src={issueAuthorAvatarUrl}
            sx={{
              mr: 2,
            }}
          />
          {issueAuthor}
        </Result.FooterItem>
        {numComments > 0 && (
          <Result.FooterItem>
            <Octicon icon={CommentDiscussionIcon} size={16} sx={{mr: 1}} />
            {numComments}
          </Result.FooterItem>
        )}
        <Result.FooterItem>
          Opened&nbsp; <Ago timestamp={createdDate} />
        </Result.FooterItem>
        <Result.FooterItem>
          <Link href={issueUrl} target="_blank" sx={{color: 'fg.muted'}}>
            #{issueNumber}
          </Link>
        </Result.FooterItem>
        {item.state_reason === 'duplicate' && <Result.FooterItem>Duplicate</Result.FooterItem>}
      </Result.Footer>
    </Result>
  )
}

try{ Issues.displayName ||= 'Issues' } catch {}
try{ IssueResult.displayName ||= 'IssueResult' } catch {}