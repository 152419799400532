import type React from 'react'
import {Box, Heading, Link, Text} from '@primer/react'
import {provideFeedback} from '../../hooks/use-search-bar-events'

export default function Feedback() {
  return (
    <Box
      sx={{
        bg: 'canvas.default',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'border.default',
        borderRadius: '6px',
        p: 3,
        py: 2,
      }}
    >
      <Heading as="h2" sx={{fontSize: 0, display: 'inline-block', fontWeight: 'semibolds'}}>
        How can we improve search?
      </Heading>{' '}
      <Link
        sx={{fontSize: 0, fontWeight: 'semibold'}}
        as="button"
        onClick={(e: React.MouseEvent) => {
          provideFeedback()
          e.preventDefault()

          // NOTE: This is necessary to prevent the dialog from instantly closing
          e.stopPropagation()
        }}
      >
        <Text sx={{fontSize: 0}}>Give feedback</Text>
      </Link>
    </Box>
  )
}

try{ Feedback.displayName ||= 'Feedback' } catch {}