import {useCallback} from 'react'
import {type NavigateOptions, useNavigate} from 'react-router-dom'
import {getUrl, type PathFunction, type PathParams, type Query} from '@github-ui/paths'

/**
 * Given a path function, returns a function that will perform a soft navigation for a set of parameters for that path.
 */
export function useSoftNavigate<T extends PathParams | void>(
  path: PathFunction<T>,
): (params: T, query?: Query, options?: NavigateOptions) => void {
  const navigate = useNavigate()

  return useCallback(
    (args: T, query?: Query, options?: NavigateOptions) => {
      const {pathname, search, hash} = getUrl(path, args, query)
      const to = {pathname, search, hash}
      navigate(to, options)
    },
    [navigate, path],
  )
}
