import {type SafeHTMLString, SafeHTMLText} from '@github-ui/safe-html'
import type {SxProp} from '@primer/react'

type SearchMatchTextProps = {
  /**
   * An escaped HTML string with an <em> around the matching substring.
   */
  text: SafeHTMLString

  /**
   * Optional prop to pass into the text component for fontWeight.
   */
  fontWeight?: string
} & SxProp

export default function SearchMatchText({text, fontWeight, sx}: SearchMatchTextProps) {
  return <SafeHTMLText className="search-match" html={text} sx={{fontWeight, '.g-emoji': {fontSize: 1}, ...sx}} />
}

try{ SearchMatchText.displayName ||= 'SearchMatchText' } catch {}