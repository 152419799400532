import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {Box, Flash, Link, Text} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {type QueryError, type ErrorRange, PARSE_ERROR_FATAL} from '../types/blackbird-types'
import {AlertIcon} from '@primer/octicons-react'

export default function SearchError({error, index}: {error: QueryError; index: number}) {
  const {search} = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const query = urlSearchParams.get('q')

  const variant = !error.type || error.type === PARSE_ERROR_FATAL ? 'danger' : 'warning'

  return (
    <Flash
      sx={{
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'warning.muted',
      }}
      key={index}
      variant={variant}
      id="search-error-message-flash"
    >
      <Octicon icon={AlertIcon} />
      {error.message}
      <QueryHelpComponent query={query} ranges={error.ranges} />
      <SuggestionComponent suggestion={error.suggestion} />
    </Flash>
  )
}

function QueryHelpComponent({query, ranges}: {query: string | null; ranges?: ErrorRange[]}) {
  const queryHelpComponent = useMemo(() => {
    const queryHelpSpans: JSX.Element[] = []
    if (ranges && query) {
      let offset = 0
      for (const range of ranges) {
        const start = range.start ?? 0
        const end = range.end ?? 0

        // Emit any prefix
        const inner = query.slice(offset, start)
        if (inner.length > 0) queryHelpSpans.push(<span key={`help-inner-${inner}`}>{inner}</span>)
        offset = start

        // Emit the highlighted range
        const warn = query.slice(range.start, end)
        if (warn.length > 0)
          queryHelpSpans.push(
            <Text key={`help-warning-${warn}`} sx={{textDecoration: 'underline dashed red'}}>
              {warn}
            </Text>,
          )
        offset = end
      }

      // Emit any final suffix
      const suffix = query.slice(offset)
      if (suffix.length > 0) queryHelpSpans.push(<span key={`help-${suffix}`}>{suffix}</span>)

      if (queryHelpSpans.length > 0) {
        return (
          <Box className="code-font-mono" sx={{fontFamily: 'mono', mt: 2}}>
            {queryHelpSpans}
          </Box>
        )
      }
    }
    return null
  }, [ranges, query])

  return queryHelpComponent
}

function SuggestionComponent({suggestion}: {suggestion?: string}) {
  const suggestionComponent = useMemo(() => {
    if (suggestion) {
      const url = `?q=${encodeURIComponent(suggestion)}`

      // TODO: Copied from blackbird-fe - we don't currently support repo/scopes
      // if (repoName) {
      //   url = `/${repoName}${url}`;
      // }

      // if (scopeName) {
      //   url += `&scopeName=${encodeURIComponent(
      //     scopeName
      //   )}&scope=${encodeURIComponent(scope)}`;
      // }

      return (
        <Box sx={{mt: 2}}>
          <Text sx={{fontSize: 0}}>
            Suggestion:{' '}
            <Link className="code-font-mono" href={url} sx={{fontFamily: 'mono'}}>
              {suggestion}
            </Link>
          </Text>
        </Box>
      )
    }
    return null
  }, [suggestion])

  return suggestionComponent
}

try{ SearchError.displayName ||= 'SearchError' } catch {}
try{ QueryHelpComponent.displayName ||= 'QueryHelpComponent' } catch {}
try{ SuggestionComponent.displayName ||= 'SuggestionComponent' } catch {}