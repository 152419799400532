import type {TextProps} from '@primer/react'
import {Text} from '@primer/react'

interface CountProps {
  value: number
  mode?: CountMode
  isLowerBound?: boolean
  ariaLabel?: string
  sx?: TextProps['sx']
}

export enum CountMode {
  Exact = 0,
  Approximate = 1,
  LowerBound = 2,
}

// Primer Pattern Request: https://github.com/github/primer/issues/956
export function Count({value, mode = CountMode.Exact, ariaLabel, sx}: CountProps) {
  if (!Number.isFinite(value)) return null

  return (
    // eslint-disable-next-line github/a11y-role-supports-aria-props
    <Text aria-label={ariaLabel} sx={sx}>
      {getValueText(value)}
      {mode === CountMode.LowerBound ? '+ ' : null}
    </Text>
  )
}

export function getCountText(value: number, mode = CountMode.Exact) {
  return getValueText(value) + (mode === CountMode.LowerBound ? '+' : '')
}

export function getValueText(value: number, max?: number): string {
  if (max !== undefined && value >= max) {
    return `${getValueText(max)}+`
  }

  if (value < 0) {
    return `-${getValueText(Math.abs(value))}`
  }

  if (value >= 1_000_000_000) {
    return `${roundToOneDecimalPlace(value / 1_000_000_000)}B`
  }

  if (value >= 100_000_000) {
    return `${Math.round(value / 1_000_000)}M`
  }

  if (value >= 1_000_000) {
    return `${roundToOneDecimalPlace(value / 1_000_000)}M`
  }

  if (value >= 100_000) {
    return `${Math.round(value / 1_000)}k`
  }

  if (value >= 1_000) {
    return `${roundToOneDecimalPlace(value / 1_000)}k`
  }

  return `${Math.round(value)}`
}

function roundToOneDecimalPlace(value: number) {
  return Math.round(value * 10) / 10
}

try{ Count.displayName ||= 'Count' } catch {}