import {Box} from '@primer/react'
import Base from './Base'

export default function FacetsSkeletonGroups() {
  return (
    <div data-testid="facets-skeleton">
      <Group widths={[196, 186, 128]} />
      <Group widths={[158, 158, 144]} />
    </div>
  )
}

function Group({widths}: {widths: number[]}) {
  return (
    <Box
      sx={{
        px: 3,
      }}
    >
      <Base
        sx={{
          mt: 3,
          width: 80,
          borderRadius: 2,
          height: 10,
        }}
      />
      <Box
        sx={{
          display: 'grid',
          gap: 0,
          pt: 2,
        }}
      >
        {widths.map((width, idx) => (
          // eslint-disable-next-line @eslint-react/no-array-index-key
          <Item key={idx} width={width} />
        ))}
      </Box>
      <Base height={1} mb={3} mt={2} />
    </Box>
  )
}

function Item({width}: {width: number}) {
  return (
    <Box
      className="item"
      sx={{
        display: 'flex',
        height: 32,
        alignItems: 'center',
      }}
    >
      <Base width={16} borderRadius={3} height={16} mr={2} />
      <Base borderRadius={3} height={12} maxWidth={width} />
    </Box>
  )
}

try{ FacetsSkeletonGroups.displayName ||= 'FacetsSkeletonGroups' } catch {}
try{ Group.displayName ||= 'Group' } catch {}
try{ Item.displayName ||= 'Item' } catch {}