import type {PropsWithChildren} from 'react'
import {Link} from '@primer/react'

// This component doesn't exist right now in any of the Primer implementations
// However it's been used in a lot of places right now.

export default function TopicLabel({children, href}: PropsWithChildren<{href?: string}>) {
  return (
    <Link
      href={href}
      sx={{
        fontSize: 0,
        display: 'inline-block',
        px: '10px',
        py: '0px',
        fontWeight: 'semibold',
        borderRadius: '2em',
        lineHeight: '22px',
        color: 'fg.accent',
        bg: 'accent.subtle',
        whiteSpace: 'nowrap',
        ':hover': {
          bg: 'accent.emphasis',
          color: 'fg.onEmphasis',
          textDecoration: 'none',
        },
      }}
    >
      {children}
    </Link>
  )
}

try{ TopicLabel.displayName ||= 'TopicLabel' } catch {}