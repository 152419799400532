// Matches app/views/site/_hovercard_template.html.erb which is the template used in the rest of the monolith
// This component's contents get set by app/assets/modules/github/behaviors/hovercards.ts
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'

export default function HoverCard() {
  const isHovercardAccessibilityEnabled = useFeatureFlag('hovercard_accessibility')
  return (
    <div
      className="Popover js-hovercard-content position-absolute"
      style={{display: 'none', outline: 'none'}}
      role={isHovercardAccessibilityEnabled ? 'dialog' : undefined}
      aria-modal={isHovercardAccessibilityEnabled ? true : undefined}
    >
      <div
        className="Popover-message Popover-message--bottom-left Popover-message--large Box color-shadow-large"
        style={{width: '360px'}}
        tabIndex={isHovercardAccessibilityEnabled ? undefined : 0}
      />
    </div>
  )
}

try{ HoverCard.displayName ||= 'HoverCard' } catch {}