import React, {useEffect} from 'react'
import {ActionList, CounterLabel} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {type Icon, ChevronDownIcon} from '@primer/octicons-react'
import LazyCounter from '../LazyCounter'
import {useSoftNavigateTo} from '../../hooks/use-soft-navigate-to'
import {getUrl, searchPath} from '@github-ui/paths'
import type {SearchKindCount} from '../../hooks/use-search-result-counts'
import type {SearchResultsType} from '../../types/blackbird-types'
import {shouldOpenInNewTab} from '../../hooks/use-navigate-to-query'
import {capitalizeFirstLetter} from '../../utilities/format-type-title'
import {useLoggedInContext} from '../../contexts/LoggedInContext'

export default function TypeGroup({
  isLoadingCounters,
  selectedType,
  isMobile,
  searchKinds,
}: {
  isLoadingCounters: boolean
  selectedType: string | undefined
  isMobile: boolean
  searchKinds: SearchKindCount[]
}) {
  const defaultItemsVisible = 6
  const indexSelection = searchKinds.findIndex(kind => kind.name === selectedType)
  const [isExpanded, setIsExpanded] = React.useState(indexSelection >= defaultItemsVisible)

  const desktopState =
    // Show all items if we have 7 items since showing 6 items + More button is
    // the same length as showing all 7 items
    isExpanded || searchKinds.length === defaultItemsVisible + 1
      ? searchKinds
      : searchKinds.slice(0, defaultItemsVisible)

  const searchKindsState = isMobile ? searchKinds : desktopState

  useEffect(() => {
    if (!isLoadingCounters) {
      const searchKindCount = searchKinds?.find(kind => kind.name === selectedType) || searchKinds[0]!
      if (searchKindCount.total === 0) {
        setIsExpanded(true)
      }
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCounters])

  return (
    <ActionList.Group data-testid="kind-group">
      {searchKindsState.map(({name, readableNamePlural, icon, total}) => {
        return (
          <TypeItem
            key={name}
            selectedType={selectedType}
            type={name || 'codelegacy'}
            title={readableNamePlural}
            icon={icon}
            isLoading={isLoadingCounters}
            totalResults={total || 0}
            isMobile={isMobile}
          />
        )
      })}
      {!isExpanded && !isMobile && searchKinds.length > defaultItemsVisible + 1 && (
        // Only show the "More" button if there are more than "defaultItemsVisible + 1" items
        // If we only show the "More" button when there are more than "defaultItemsVisible" items,
        // We will end up in a case where we have 7 items, but only show 6, and the "More" button
        // Which is annoying for users since the more button takes up just as much space as the 7th item
        <ActionList.Item onClick={() => setIsExpanded(true)} onSelect={() => setIsExpanded(true)}>
          <ActionList.LeadingVisual>
            <Octicon icon={ChevronDownIcon} size={16} sx={{mr: 2, color: 'fg.muted'}} />
          </ActionList.LeadingVisual>
          More
        </ActionList.Item>
      )}
    </ActionList.Group>
  )
}

function TypeItem({
  title,
  icon,
  selectedType,
  isMobile,
  type,
  selectedAction,
  totalResults,
  isLoading,
  resultCountIsLowerBound,
}: {
  title: string
  icon: Icon
  selectedType: string | undefined
  type: SearchResultsType
  selectedAction?: () => void
  totalResults: number
  isMobile: boolean
  isLoading: boolean
  resultCountIsLowerBound?: boolean
}) {
  const [, navigateToSearchType] = useSoftNavigateTo(searchPath, undefined, {
    p: null,
    type,
    l: null,
    s: null,
    o: null,
  })
  const loggedIn = useLoggedInContext()

  const url = getUrl(searchPath, undefined, {p: null, type, l: null, s: null, o: null}).toString()
  const navigate = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (shouldOpenInNewTab(e)) {
      window.open(url, '_blank')
      e.preventDefault()
      e.stopPropagation()
    } else {
      navigateToSearchType(e)
      e.preventDefault()
      e.stopPropagation()
    }
    if (selectedAction) selectedAction()
  }

  const itemIcon = <Octicon icon={icon} size={16} sx={{mr: 2, color: 'fg.muted'}} />
  const contents = <>{capitalizeFirstLetter(title)}</>
  const counter =
    !loggedIn && type === 'code' ? (
      <CounterLabel data-testid="resolved-count-label" sx={{height: 20, lineHeight: '20px', py: 0, px: 2}}>
        ...
      </CounterLabel>
    ) : (
      <LazyCounter
        resultCountIsLowerBound={resultCountIsLowerBound}
        totalResults={totalResults}
        isLoading={isLoading}
      />
    )

  if (isMobile) {
    return (
      <ActionList.Item
        key={type}
        data-testid={`nav-item-${type}`}
        role="menuitemradio"
        selected={selectedType === type}
        aria-checked={selectedType === type}
        onSelect={navigate}
      >
        <ActionList.LeadingVisual>{itemIcon}</ActionList.LeadingVisual>
        {contents}
        <ActionList.TrailingVisual sx={{display: 'flex'}}>
          {counter}
          <span className="sr-only">results</span>
        </ActionList.TrailingVisual>
      </ActionList.Item>
    )
  }

  return (
    <ActionList.LinkItem onClick={navigate} href={url} active={selectedType === type} data-testid={`nav-item-${type}`}>
      <ActionList.LeadingVisual>{itemIcon}</ActionList.LeadingVisual>
      {contents}
      <ActionList.TrailingVisual sx={{display: 'flex'}}>
        {counter}
        <span className="sr-only">results</span>
      </ActionList.TrailingVisual>
    </ActionList.LinkItem>
  )
}

try{ TypeGroup.displayName ||= 'TypeGroup' } catch {}
try{ TypeItem.displayName ||= 'TypeItem' } catch {}