import {useLocation} from 'react-router-dom'
import {Text, Link} from '@primer/react'
import {advancedSearchPath, getRelativeHref} from '@github-ui/paths'
import type {SearchKind} from '../../hooks/use-search-result-counts'

export default function Footer({resultKind}: {resultKind: SearchKind}) {
  const {search} = useLocation()
  const query = new URLSearchParams(search).get('q')
  const href = getRelativeHref(advancedSearchPath, undefined, {q: query})

  if (resultKind.name === 'code') {
    return null
  }

  return (
    <Text sx={{mt: 2, textAlign: ['center', 'center', 'center', 'left'], width: '100%', display: 'block', fontSize: 0}}>
      You could try an{' '}
      <Link inline href={href}>
        advanced search
      </Link>
      .
    </Text>
  )
}

try{ Footer.displayName ||= 'Footer' } catch {}