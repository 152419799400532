import {CounterLabel} from '@primer/react'
import {Count} from '../../react-shared/Count'
import Base from '../components/skeletons/Base'

export default function LazyCounter({
  totalResults,
  isLoading,
  resultCountIsLowerBound,
}: {
  totalResults: number
  isLoading: boolean
  resultCountIsLowerBound?: boolean
}) {
  if (isLoading) {
    return <Base sx={{height: 20, width: 20, ml: 2, borderRadius: 3}} />
  }

  return (
    <CounterLabel data-testid="resolved-count-label" sx={{height: 20, lineHeight: '20px', py: 0, px: 2}}>
      <Count isLowerBound={resultCountIsLowerBound} value={totalResults} />
    </CounterLabel>
  )
}

try{ LazyCounter.displayName ||= 'LazyCounter' } catch {}