import {Box, type BoxProps} from '@primer/react'
import styled, {keyframes} from 'styled-components'

const shine = keyframes`
    0% { opacity:1; }
    50% { opacity:0.6; }
    100% { opacity:1; }
`

const Animation = styled(Box)`
  background-size: 200% 100%;
  animation: ${shine} 1.5s linear infinite;
`

export default function Base(props: BoxProps) {
  return <Animation width="100%" bg="neutral.subtle" {...props} />
}

try{ Animation.displayName ||= 'Animation' } catch {}
try{ Base.displayName ||= 'Base' } catch {}