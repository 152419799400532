import type React from 'react'
import {useMemo} from 'react'
import {AnalyticsProvider} from '@github-ui/analytics-provider'
import {useSearchBarEvents} from './hooks/use-search-bar-events'
import {FocusHintContextProvider} from '@github-ui/focus-hint-context'

export function App({children}: {children?: React.ReactNode}) {
  useSearchBarEvents()

  const metadata = useMemo(() => ({}), [])
  return (
    <AnalyticsProvider appName={'blackbird'} category={''} metadata={metadata}>
      <FocusHintContextProvider>{children}</FocusHintContextProvider>
    </AnalyticsProvider>
  )
}

try{ App.displayName ||= 'App' } catch {}