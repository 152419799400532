import React from 'react'
import {Box, Text} from '@primer/react'

interface TipsConentListType {
  value: React.ReactNode
  name: string
}

export default function TipsContentList({data}: {data: TipsConentListType[]}) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto', 'auto', '180px auto'],
      }}
    >
      {data.map((d, index) => {
        const isEnd = index + 1 === data.length
        return (
          <React.Fragment key={d.name}>
            <Box
              sx={{
                pb: [1, 1, 2],
                height: ['auto', 'auto', 40],
                display: 'flex',
                alignItems: 'center',
                pt: index === 0 ? 0 : 2,
              }}
            >
              <Text sx={{fontSize: 0, fontWeight: 'semibold'}}>{d.name}</Text>
            </Box>
            <Box
              sx={{
                borderBottomColor: 'border.subtle',
                borderBottomWidth: [1, 1, isEnd ? 0 : 1],
                borderBottomStyle: 'solid',
                pb: [3, 3, 2],
                mb: [2, 2, 0],
                pt: [2, 2, index === 0 ? 0 : 2],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text sx={{fontSize: 0, fontFamily: 'mono', lineHeight: 2}}>{d.value}</Text>
            </Box>
          </React.Fragment>
        )
      })}
    </Box>
  )
}

try{ TipsContentList.displayName ||= 'TipsContentList' } catch {}