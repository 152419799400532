import {Fragment, type PropsWithChildren, useState} from 'react'
import {Box, Link} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {ChevronRightIcon, GitBranchIcon, ChevronDownIcon} from '@primer/octicons-react'
import type {LegacyCodeResults, LegacyCodeResultItem} from '../../types/blackbird-types'
import Result from '../search-result'
import LanguageCircle from '../LanguageCircle'
import {SafeHTMLBox} from '@github-ui/safe-html'
import {blobPath, encodePart, ownerAvatarPath} from '@github-ui/paths'
import EllipsisOverflow from '../EllipsisOverflow'
import {GitHubAvatar} from '@github-ui/github-avatar'

export default function LegacyCode({results}: {results: LegacyCodeResults}) {
  return (
    <Result.List sx={{gap: [3, 3, 4, 4]}}>
      {results.results.map(item => (
        <LegacyCodeResult key={item.id} item={item} />
      ))}
    </Result.List>
  )
}

function LegacyCodeResult({item}: {item: LegacyCodeResultItem}) {
  const [isOpen, setIsOpen] = useState(true)

  const destinationPath = blobPath({
    owner: item.owner_login,
    repo: item.repo.repository.name,
    commitish: item.commit_sha,
    filePath: item.path,
  })

  return (
    <Box sx={{minWidth: 0}}>
      <Box
        sx={{
          bg: 'canvas.subtle',
          userSelect: 'none',
          pl: 2,
          pr: 3,
          height: 40,
          listStyle: 'none',
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          borderBottomRightRadius: isOpen ? 0 : 2,
          borderBottomLeftRadius: isOpen ? 0 : 2,
          borderColor: 'border.default',
          borderWidth: 1,
          borderStyle: 'solid',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <OpenCloseButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          name={`${item.owner_login}/${item.repo.repository.name}`}
        />
        <Box sx={{flex: 1, alignItems: 'center', display: 'flex', ml: 1, minWidth: 0}}>
          <Result.Avatar mr="6px">
            <GitHubAvatar square sx={{ml: '1px'}} size={16} src={ownerAvatarPath({owner: item.owner_login})} />
          </Result.Avatar>
          <Result.Title fontSize={1}>
            <EllipsisOverflow side="left" title={`${item.owner_login}/${item.repo.repository.name} · item.hl_path`}>
              <Result.RepositoryLink
                owner={item.owner_login}
                name={item.repo.repository.name}
                sx={{color: 'fg.default', fontWeight: 'bold'}}
              />
              &nbsp;·&nbsp;
              <Link href={destinationPath} sx={{fontFamily: 'mono', color: 'fg.default', fontSize: 0}}>
                {item.hl_path ? <Result.SearchMatchText text={item.hl_path} /> : <span>{item.path}</span>}
              </Link>
            </EllipsisOverflow>
          </Result.Title>
        </Box>
        <Box sx={{flexShrink: 0, pl: 4, display: ['none', 'none', 'flex']}}>
          <Result.Footer>
            {item.language && (
              <Result.FooterItem>
                <IconWithText ariaLabel={`${item.language} language`} text={item.language}>
                  <Box sx={{mr: 2}}>
                    <LanguageCircle color={item.language_color ?? 'gray'} />
                  </Box>
                </IconWithText>
              </Result.FooterItem>
            )}
            {item.repo_default_branch && (
              <Result.FooterItem>
                <IconWithText
                  ariaLabel={`${item.repo_default_branch} branch`}
                  text={item.repo_default_branch}
                  textUrl={`${item.repo.repository.name}/tree/${encodePart(item.repo_default_branch)}`}
                >
                  <Octicon
                    icon={GitBranchIcon}
                    size={16}
                    sx={{
                      mr: 1,
                    }}
                  />
                </IconWithText>
              </Result.FooterItem>
            )}
          </Result.Footer>
        </Box>
      </Box>
      <Result.Header />

      {isOpen && (
        <Box
          // 'notranslate' is to tell Google translate to not bother
          className="notranslate"
          sx={{
            borderColor: 'border.default',
            borderBottomWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderStyle: 'solid',
          }}
        >
          <CodeSnippetFrame>
            <LegacyCodeSnippet item={item} />
          </CodeSnippetFrame>
        </Box>
      )}
    </Box>
  )
}

function OpenCloseButton({isOpen, onClick, name}: {isOpen: boolean; onClick: () => void; name: string}) {
  return (
    <Box
      as="button"
      onClick={onClick}
      aria-label={name}
      aria-expanded={isOpen}
      sx={{
        height: 24,
        borderRadius: 2,
        width: 24,
        color: 'fg.muted',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bg: 'canvas.subtle',
        border: 'none',
        ':hover': {
          bg: 'btn.hoverBg',
        },
      }}
    >
      {isOpen ? <ChevronDownIcon size={16} /> : <ChevronRightIcon size={16} />}
    </Box>
  )
}

function LegacyCodeSnippet({item}: {item: LegacyCodeResultItem}) {
  return (
    <table>
      <tbody>
        {item.hl_fragments.map((fragment, i) => (
          // eslint-disable-next-line @eslint-react/no-array-index-key
          <Fragment key={i}>
            {fragment.lines.map(({line_number, line}) => (
              <tr key={line_number}>
                <Box as="td" className="blob-num" sx={{paddingRight: 2}}>
                  <Link
                    href={blobPath({
                      owner: item.owner_login,
                      repo: item.repo.repository.name,
                      commitish: item.commit_sha,
                      filePath: item.path,
                    })}
                    sx={{color: 'accent.fg'}}
                  >
                    {line_number + 1}
                  </Link>
                </Box>
                <SafeHTMLBox as="td" className="blob-code blob-code-inner" html={line} />
              </tr>
            ))}
            {i === item.hl_fragments.length - 1 ? null : <FragmentDivider />}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

function FragmentDivider() {
  return (
    <Box as="tr" sx={{height: 1}}>
      <Box as="td" className="blob-num" sx={{paddingRight: 2}}>
        &hellip;
      </Box>
      <td className="blob-code blob-code-inner" />
    </Box>
  )
}

function CodeSnippetFrame({children}: PropsWithChildren) {
  return (
    <Box
      // 'notranslate' is to tell Google translate to not bother
      className="code-list notranslate"
      sx={{
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
        minWidth: 0,
        py: 2,
      }}
    >
      {children}
    </Box>
  )
}

interface IconWithTextProps extends PropsWithChildren {
  ariaLabel: string
  text: string
  textUrl?: string
}

function IconWithText({ariaLabel, text, textUrl, children}: IconWithTextProps) {
  const inner = (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {children}
      {/* eslint-disable-next-line github/a11y-role-supports-aria-props */}
      <span aria-label={ariaLabel}>{text}</span>
    </Box>
  )

  if (!textUrl) return inner

  return (
    <Link href={textUrl} sx={{color: 'fg.muted'}}>
      {inner}
    </Link>
  )
}

try{ LegacyCode.displayName ||= 'LegacyCode' } catch {}
try{ LegacyCodeResult.displayName ||= 'LegacyCodeResult' } catch {}
try{ OpenCloseButton.displayName ||= 'OpenCloseButton' } catch {}
try{ LegacyCodeSnippet.displayName ||= 'LegacyCodeSnippet' } catch {}
try{ FragmentDivider.displayName ||= 'FragmentDivider' } catch {}
try{ CodeSnippetFrame.displayName ||= 'CodeSnippetFrame' } catch {}
try{ IconWithText.displayName ||= 'IconWithText' } catch {}