import {ActionList, ActionMenu, Box, IconButton} from '@primer/react'
import type {PropsWithChildren} from 'react'
import {KebabHorizontalIcon} from '@primer/octicons-react'

export default function Header({children}: PropsWithChildren) {
  return (
    <Box
      as="h3"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        fontWeight: 500,
      }}
    >
      {children}
    </Box>
  )
}

interface AvatarProps extends PropsWithChildren {
  mr?: string | number
}
export function Avatar({mr, children}: AvatarProps) {
  return <Box sx={{display: 'flex', mr: mr ?? '10px'}}>{children}</Box>
}

interface TitleProps {
  /**
   * Optional fontSize override
   * @default 2
   */
  fontSize?: number
}

export function Title({children, fontSize}: PropsWithChildren<TitleProps>) {
  return (
    <Box
      className="search-title"
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        fontSize: fontSize ?? 2,
        fontWeight: 'semibold',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

/**
 * For best results, children should be ActionList.Item components.
 */
export function OverflowMenu({children}: PropsWithChildren) {
  return (
    <ActionMenu>
      <ActionMenu.Anchor>
        {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
        <IconButton unsafeDisableTooltip icon={KebabHorizontalIcon} aria-label="More" variant="invisible" />
      </ActionMenu.Anchor>

      <ActionMenu.Overlay>
        <ActionList>{children}</ActionList>
      </ActionMenu.Overlay>
    </ActionMenu>
  )
}

try{ Header.displayName ||= 'Header' } catch {}
try{ Avatar.displayName ||= 'Avatar' } catch {}
try{ Title.displayName ||= 'Title' } catch {}
try{ OverflowMenu.displayName ||= 'OverflowMenu' } catch {}