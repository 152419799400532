import {searchPath} from '@github-ui/paths'
import {useLocation} from 'react-router-dom'
import {SoftLink} from './SoftLink'
import {Flash} from '@primer/react'
import {
  chooseSearchType,
  extractUnsupportedQualifiers,
  mapSearchTypeToURLParam,
  mapURLParamToSearchType,
  parseString,
  SearchType,
  searchTypeAsPlural,
  type SearchTypeURLParameter,
} from '../../../../../app/components/search/parsing/parsing'
export interface SearchTypeErrorMessage {
  message: string
  suggestion: string
  suggestedSearchType: SearchTypeURLParameter
}

export function getSearchTypeError(
  currentType: SearchTypeURLParameter,
  query: string,
  loggedIn: boolean,
): SearchTypeErrorMessage | null {
  if (!query) {
    return null
  }

  const ast = parseString(query)
  const searchType = mapURLParamToSearchType(currentType)
  const unsupportedQualifiers = extractUnsupportedQualifiers(ast, searchType)

  // Don't show warnings when search type is unknown. Instead, resolve to a
  // specific search type based on the query, and redirect.
  if (searchType === SearchType.Unknown) {
    return null
  }

  if (unsupportedQualifiers.length === 0) {
    return null
  }

  const preferredType = chooseSearchType(ast, loggedIn)
  let suggestion = `Try searching for ${searchTypeAsPlural(preferredType)} instead?`
  if (preferredType === SearchType.Marketplace) {
    suggestion = 'Try searching in the marketplace instead?'
  } else if (preferredType === SearchType.Unknown) {
    suggestion = ''
  }

  let message = `Some qualifiers in your query (${unsupportedQualifiers
    .slice(0, 3)
    .map(q => q.toLowerCase())
    .join(', ')}) are not supported when searching ${searchTypeAsPlural(searchType)}.`
  if (unsupportedQualifiers.length === 1) {
    message = `The ${unsupportedQualifiers[0]!.toLowerCase()} qualifier is not supported when searching ${searchTypeAsPlural(
      searchType,
    )}.`
  }

  return {
    message,
    suggestion,
    suggestedSearchType: mapSearchTypeToURLParam(preferredType),
  }
}

export default function SearchTypeError({message, suggestion, suggestedSearchType}: SearchTypeErrorMessage) {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const query = urlSearchParams.get('q')

  if (!query) return null

  return (
    <Flash
      variant="warning"
      sx={{
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'warning.muted',
      }}
    >
      <span>{message}</span>
      {suggestion ? (
        <span>
          {' '}
          <SoftLink path={searchPath} query={{query, type: suggestedSearchType}}>
            <span>{suggestion}</span>
          </SoftLink>
        </span>
      ) : null}
    </Flash>
  )
}

try{ SearchTypeError.displayName ||= 'SearchTypeError' } catch {}