import {createContext, useContext} from 'react'

const LoggedInContext = createContext<boolean>(true)

export default LoggedInContext

export function useLoggedInContext() {
  return useContext(LoggedInContext)
}

try{ LoggedInContext.displayName ||= 'LoggedInContext' } catch {}