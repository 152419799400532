import type React from 'react'
import {Box} from '@primer/react'

export default function EllipsisOverflow({
  side,
  children,
  sx,
  title,
}: {
  side: 'left' | 'right'
  children: React.ReactNode
  sx?: object
  title: string
}) {
  return (
    <Box
      title={title}
      sx={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        direction: side === 'left' ? 'rtl' : 'ltr',
        whiteSpace: 'nowrap',
        ...(sx || {}),
      }}
    >
      {children}
    </Box>
  )
}

try{ EllipsisOverflow.displayName ||= 'EllipsisOverflow' } catch {}