import {Ago} from '@github-ui/ago'
import type {WikiResultItem, WikisResults} from '../../types/blackbird-types'
import {Link} from '@primer/react'
import Result from '../search-result'
import {encodePart} from '@github-ui/paths'

function Wikis({results}: {results: WikisResults}) {
  return (
    <Result.List>
      {results.results.map(item => (
        <WikiResult key={`wiki-result-${item.id}`} item={item} />
      ))}
    </Result.List>
  )
}

function WikiResult({item}: {item: WikiResultItem}) {
  const repositoryName = item.repo.repository.name
  const repositoryOwnerName = item.repo.repository.owner_login
  // wiki URLs only use the filename without the extension, even if the actual file is nested in a directory
  const filepath = item.filename.substring(0, item.filename.lastIndexOf('.'))
  const wikiUrl = `/${repositoryOwnerName}/${repositoryName}/wiki/${encodePart(filepath)}`
  const lastUpdatedDate = new Date(item.updated_at)
  return (
    <Result>
      <Result.Header>
        <Result.RepositoryContext owner={repositoryOwnerName} name={repositoryName} />
        <Result.Title>
          <Link href={wikiUrl} target="_blank">
            <Result.SearchMatchText text={item.hl_title} />
          </Link>
        </Result.Title>
      </Result.Header>
      {item.hl_body && (
        <Result.Content>
          <Result.SearchMatchText text={item.hl_body} />
        </Result.Content>
      )}
      <Result.Footer>
        <Result.FooterItem>
          <span>
            {`Last updated `}
            <Ago timestamp={lastUpdatedDate} />
          </span>
        </Result.FooterItem>
      </Result.Footer>
    </Result>
  )
}

export default Wikis

try{ Wikis.displayName ||= 'Wikis' } catch {}
try{ WikiResult.displayName ||= 'WikiResult' } catch {}